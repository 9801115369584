import React from 'react';
import ReactDOM from 'react-dom/client';
import logo from './logo.svg';
import './App.css';
import { BrowserRouter, Route, Switch, useNavigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.css';
import Signin from './Signin';
import TaskMaster from "./TaskMaster";

function App() {
	const history = useNavigate();
	const token = localStorage.getItem('accessToken');
	
	function getUrlParameter(name) {
        name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
        var regex = new RegExp("[\\?&]" + name + "=([^&#]*)"),
        results = regex.exec(window.location.search);
        return results === null ? "" : decodeURIComponent(results[1].replace(/\+/g, " "));
    }
	
	if(!token) {
		let uid = getUrlParameter('taskGuid');
		let substring = '';
		if(uid){
			substring = '?taskGuid='+uid;
		}
		setTimeout(function(){
			history('/login'+substring);
		}, 1000);	
		return <Signin />
	}else{
	
		return (
			<div className="App">
				<TaskMaster />
			</div>
		);
	}
}

export default App;
