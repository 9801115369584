import React,{ Component } from 'react';
import $ from 'jquery';
import MainHeader from "./component/Header/MainHeader";
import TaskManager from "./component/TaskManager";
import WorkFlowManager from "./component/WorkFlowManager";
import StartWorkFlow from "./component/StartWorkFlow";
import WorkFlowBuilder from "./component/WorkFlowBuilder";
import WorkFlowDetails from "./component/WorkFlowDetails";
import WorkFlowProgress from "./component/WorkFlowProgress";
import ScheduledworkFlow from "./component/ScheduledworkFlow";
import UserManager from "./component/UserManager";
import GoToTask from "./component/goToTask";
import GoToManager from "./component/goToManager";
import GoToValues from "./component/goToValues";

class TaskMaster extends Component {
	
	constructor(props) {
        super(props);
	 	this.state ={
			taskManager:localStorage.getItem('role') == 'Subscriber' || localStorage.getItem('role') == 'General' || localStorage.getItem('role') == 'Scan' ? false : true,
			workFlow:false,
			workFlowManager:false,
			workflowStart:false,
			workFlowDetails:false,
			workFlowProgress:localStorage.getItem('role') == 'Subscriber' ? true : false,
			scheduledworkFlow:false,
			userManager:false,
			goToManager:false,
			goToValues:false,
			goToTask:localStorage.getItem('role') == 'Scan' ? true : false,
	 	}
    }
	
	componentDidMount() {
		let uid = this.getUrlParameter('taskGuid');
		if(uid){
			this.setState({workFlow:false,taskManager:false,workFlowManager:false,workflowStart:false,workFlowDetails:false,workFlowProgress:false,scheduledworkFlow:false,userManager:false,goToTask:true,goToManager:false,goToValues:false});
		}
	}
	
	getUrlParameter = (name) => {
        name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
        var regex = new RegExp("[\\?&]" + name + "=([^&#]*)"),
        results = regex.exec(window.location.search);
        return results === null ? "" : decodeURIComponent(results[1].replace(/\+/g, " "));
    }

	builderOption = (event) => {
		let val = event.target.value;
		this.setState({workFlow:false,taskManager:false,workFlowManager:false,workflowStart:false,workFlowDetails:false,workFlowProgress:false,scheduledworkFlow:false,userManager:false,goToTask:false,goToManager:false,goToValues:false});
		if(val == 'workFlow'){
			this.setState({workFlow:true});
		}else if(val == 'taskManager'){
			this.setState({taskManager:true});
		}else if(val == 'workFlowManager'){
			this.setState({workFlowManager:true});
		}else if(val == 'workflowStart'){
			this.setState({workflowStart:true});
		}else if(val == 'workFlowDetails'){
			this.setState({workFlowDetails:true});
		}else if(val == 'workFlowProgress'){
			this.setState({workFlowProgress:true});
		}else if(val == 'scheduledworkFlow'){
			this.setState({scheduledworkFlow:true});
		}else if(val == 'userManager'){
			this.setState({userManager:true});
		}else if(val == 'goToTask'){
			this.setState({goToTask:true});
		}else if(val == 'goToManager'){
			this.setState({goToManager:true});
		}else if(val == 'goToValues'){
			this.setState({goToValues:true});
		}
    }
	
    render(){
        const {taskManager,workFlow,workFlowManager,workflowStart,workFlowDetails,workFlowProgress,scheduledworkFlow,userManager,goToTask,goToManager,goToValues} = this.state;
    	return (
			<div className="full-container">
				<div className="card">
					<MainHeader builderOption = {this.builderOption} />
					{taskManager ?
					<div id="wrapper" className="toggled">
						<TaskManager />
					</div>
					:null}
					
					
					{workFlow ? 
					<div id="wrapper" className="toggled">
						<WorkFlowBuilder />
					</div>
					:null}
					
					{workFlowManager ? 
					<div id="wrapper" className="toggled">
						<WorkFlowManager />
					</div>
					:null}
					
					{workflowStart ? 
					<div id="wrapper" className="toggled">
						<StartWorkFlow />
					</div>
					:null}
					
					{workFlowDetails ? 
						<div id="wrapper" className="toggled">
						<WorkFlowDetails />
						</div>
					:null}
					
					{workFlowProgress ? 
						<WorkFlowProgress />
					:null}
					
					{scheduledworkFlow ? 
						<ScheduledworkFlow />
					:null}
					
					{userManager ? 
						<UserManager />
					:null}
					
					{goToTask ? 
						<GoToTask />
					:null}
					
					{goToManager ? 
					<div id="wrapper" className="toggled">
						<GoToManager />
					</div>
					:null}
					
					{goToValues ? 
					<div id="wrapper" className="toggled">
						<GoToValues />
					</div>
					:null}
					
				</div>           
			</div>           
		)
	}
	
}

export default TaskMaster;