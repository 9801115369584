import React, { Component } from 'react';
import $ from 'jquery';
import axios,{post,get} from 'axios';
import moment from 'moment';
import { ulid } from 'ulid';
import arrayMove from "./arrayMove";
import { CSVLink } from "react-csv";
import { DataGrid } from '@mui/x-data-grid';
import {
  sortableContainer,
  sortableElement,
  sortableHandle,
} from 'react-sortable-hoc';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import { Button, Table, TableBody, TableContainer, TableHead, TableRow } from '@mui/material';
import Paper from '@mui/material/Paper';
import TablePagination from '@mui/material/TablePagination';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';


export default class WorkFlowManager extends Component {
	
	constructor(props){
        super(props)
		this.state ={
			workflowList:[],
			workflowFilter:[],
			userData:[],
			catList:[],
			catSubList:[],
			authors:[],
			taskList:[],
			workflowInfo:[],
			filterAuthor:'',
			filterCategory:'',
			filterSubCategory:'',
			actionFilter:1,
			shareableFilter:'Y',
			addEditCategory:false,
			confirmOpen:false,
			confirmOpenActive:false,
			wId:'',
			cusCategoryName:'',
			addEditSubCategory:false,
			previewName:'',
			selectedWorkflowName:'',
			notShareable:'N',
			daysCount:0,
			page:0,
			rowsPerPage:10,
		}
    }
	
	componentDidMount() {
		this.getTaskMasterUsers();
		this.getWorkflow();	
		this.getCategories();	
		this.loadScript();	
		this.getUsers();	
	}
	
	loadScript() {
		$(function() {
		  'use strict';
		  $('.js-menu-toggle').click(function(e) {
			var $this = $(this);
			if ( $('#wrapper').hasClass('toggled') ) {
				$('#wrapper').removeClass('toggled');
				$this.removeClass('active');
			} else {
				$('#wrapper').addClass('toggled');	
				$this.addClass('active');
			}
			e.preventDefault();
		  });
		});
	}
	
	getUsers=() => {
		let ApiUrl = $('#ApiUrl').val();
		let url = ApiUrl+'users';
		let data = [];
		axios.get(url)
        .then(response => {
			data = response.data;
			let usersArray = [];
			data.map(function(row,i) {
				usersArray.push(row);
			});	
			this.setState({users:usersArray});
			
		}).catch(error => {
			alert('error::'+ error);
		})
    }
	
	/* getCategories=()=>{
		let ApiUrl = $('#ApiUrl').val();
		let that = this;
		let url = ApiUrl+'list-name';
		let data = [];
		axios.get(url)
        .then(response => {
            data = response.data;
			this.setState({catList:data});
			setTimeout(function(){
				that.getTaskList();
			}, 2000);
        })
	} */
	
	getWorkflow=()=>{
		let ApiUrl = $('#ApiUrl').val();
		let that = this;
		let url = ApiUrl+'workflows';
		let data = [];
		axios.get(url)
        .then(response => {
            data = response.data;
			let authors = [];
			data.map((val,i)=>{
				authors.push(val.user_id);
			});
			let authorsIds = that.uniqueArray(authors);
			
			this.setState({workflowList:data,authors:authorsIds});
			
			setTimeout(function(){
				that.dataFilter();
			}, 1000);
        })
	}
	
	getCategories=()=>{
		let ApiUrl = $('#ApiUrl').val();
		let that = this;
		let url = ApiUrl+'workflow-categories';
		let data = [];
		axios.get(url)
        .then(response => {
            data = response.data;
			this.setState({catList:data});
        })
	}
	
	getSubCategories=(id)=>{
		let ApiUrl = $('#ApiUrl').val();
		let that = this;
		let url = ApiUrl+'workflow-sub-categories/'+id;
		let data = [];
		axios.get(url)
        .then(response => {
            data = response.data;
			this.setState({catSubList:data});
        })
	}
	
	getTaskMasterUsers=() => {
		
		let user = localStorage.getItem('username');
		
		let ApiUrl = $('#ApiUrl').val();
		let url = ApiUrl+'authors';
		let data = [];
		axios.get(url)
        .then(response => {
			data = response.data;
			
			let userDt = [];
			
			data.map(function(val,n) {
				let user = [val.user_guid,val.user_name];
				userDt.push(user);
			})
			this.setState({userData:userDt});
			
		}).catch(error => {
			alert('error::'+ error);
		})
    }
	
	getValue=(event)=>{
		let name = event.target.name;
		let res = event.target.value;
		
		if(name != 'notShareable'){
			this.setState({[event.target.name]:event.target.value});
		}
		
		if($("input[name='actionFilter']").prop("checked") == true){
			this.setState({actionFilter:1});
			
		}else{
			this.setState({actionFilter:0});
		}
		
		if($("input[name='shareableFilter']").prop("checked") == true){
			this.setState({shareableFilter:'Y'});
		}else{
			this.setState({shareableFilter:'N'});
		}
		
		if(name == 'notShareable' && $("input[name='notShareable']").prop("checked") == true){
			this.setState({notShareable:'N'});
		}
		
		if(name == 'notShareable' && $("input[name='notShareable']").prop("checked") == false){
			this.setState({notShareable:'Y'});
		}
		
		if(name == 'wEditSubCategory'){
			let selectedCategoryName = event.target.selectedOptions[0].text;
			this.setState({addEditSubCategory:false,cusSubCategoryName:selectedCategoryName});
		}
		
		if(name == 'wEditCategory'){
			this.getSubCategories(res);
			let selectedCategoryName = event.target.selectedOptions[0].text;
			this.setState({addEditCategory:false,wcatName:selectedCategoryName});
		}
		
		if(name == 'filterCategory' || name == 'wCategory'){
			this.getSubCategories(res);
			//let selectedCategoryName = event.target.selectedOptions[0].text;
			//this.setState({addEditCategory:false,wcatName:selectedCategoryName});
		}
		
		let that = this;
		setTimeout(function(){
			that.dataFilter();
		}, 1000);
	}
	
	dataFilter=() => {
		let workflowFilter = [];
		let that = this;
		this.state.workflowList.map(function(row,i) {
			let userName = 'Admin';
			that.state.userData.map(function(user,i) {
				if(user[0] == row.user_id){
					userName = user[1];
				}
			})
			
			if(that.state.filterAuthor && that.state.filterAuthor != userName){
				return false;
			}
			
			if(that.state.filterCategory && that.state.filterCategory != row.category){
				return false;
			}
			
			if(that.state.filterSubCategory && that.state.filterSubCategory != row.subcategory){
				return false;
			}
			
			if(that.state.actionFilter != row.action){
				return false;
			}
			
			if(that.state.shareableFilter != row.shareable){
				return false;
			}
			
			workflowFilter.push(row);
			
		});
		this.setState({workflowFilter,page:0});
	}
	
	copyWorkflow=(id,name) => {
		
		let currentDate = new Date();
		let newWorkflowName = 'Copy-'+name+'-'+moment(currentDate).format('HHMMSS');
		
		if(id){
			let ApiUrl = $('#ApiUrl').val();
			let url = ApiUrl+'workflow-tasks/'+id;
			
			axios.get(url).then(response => {
				let data = response.data;
				let that = this;
				setTimeout(function(){
					let tasks = JSON.stringify(data);
					if(newWorkflowName && tasks){
						
						let ApiUrl = $('#ApiUrl').val();
						let url = ApiUrl+'import-workflow';
						let user_id = localStorage.getItem('user_id');
						let user_facility_id = localStorage.getItem('user_facility_id');
						let uid = ulid();
					
						let formData = new FormData();
						formData.append('name', newWorkflowName);
						formData.append('uid', uid);
						formData.append('user_id', user_id);
						formData.append('facility_id', user_facility_id);
						formData.append('tasks', tasks);
						axios({
							method: 'POST',
							url: url,
							data: formData,
							headers: {
								'Content-Type': 'multipart/form-data'
							}
						})
						.then(response => {
							if(response.data){
								that.getWorkflow();
								alert(response.data);
							}
						}).catch(error => {
							alert('error::'+ error);
						})
						
					}else{
						alert('Data not found!');
					}
				}, 1000);
			})
		}

	}
	
	deleteWorkflow=(id) => {
		if(id){
			let ApiUrl = $('#ApiUrl').val();
			let url = ApiUrl+'delete-workflow/'+id;
			axios({
				method: 'delete',
				url: url,
			})
			.then(response => {
				this.getWorkflow();
				alert(response.data);
			}).catch(error => {
				alert('error::'+ error);
			})
		}
	}
	
	editWorkflow=(id,name,rank,category,subcategory,shareable) => {
		this.getSubCategories(category);
		this.setState({wId:id,wName:name,wRank:rank,wCategory:category,wSubCategory:subcategory,notShareable:shareable});
	}
	
	uniqueArray(arr) {
		var a = [];
		for (var i=0, l=arr.length; i<l; i++)
			if (a.indexOf(arr[i]) === -1 && arr[i] !== '')
				a.push(arr[i]);
		return a;
	}
	
	addCategory=() => {
		this.setState({addEditCategory:true,cusCategoryName:'',editcatId:''});
	}
	
	addSubCategory=() => {
		this.setState({addEditSubCategory:true,cusCategoryName:'',editcatId:''});
	}
	
	editCategory=() => {
		let catName = this.state.wcatName;
		this.setState({addEditCategory:true,cusCategoryName:catName,editcatId:this.state.wEditCategory});
	}
	
	editSubCategory=() => {
		let catName = this.state.cusSubCategoryName;
		this.setState({addEditSubCategory:true,cusSubCategoryName:catName,editcatId:this.state.wEditSubCategory});
	}
	
	addUpdateCategory=() => {
		
		let catName = this.state.cusCategoryName;
		let wEditCategory = this.state.wEditCategory;
		let catSubName = this.state.cusSubCategoryName;
		let editcatId = this.state.editcatId;
		let user_id = localStorage.getItem('user_id');
		if(catName || catSubName){
			let ApiUrl = $('#ApiUrl').val();
			let url = ApiUrl+'manage-categories';
			let formData = new FormData();
			formData.append('id', editcatId);
			if(catSubName){
				formData.append('parent_id', wEditCategory);
				formData.append('name', catSubName);
			}else{
				formData.append('name', catName);
			}
			formData.append('user_id', user_id);
			axios({
				method: 'POST',
				url: url,
				data: formData,
				headers: {
					'Content-Type': 'multipart/form-data'
				}
			})
			.then(response => {
				if(response.data){
					this.getCategories();
					this.setState({cusCategoryName:'',cusSubCategoryName:'',addEditCategory:false,wcatName:'',wEditCategory:'',addEditSubCategory:false});
					$('.w-edit-category').val('');
					alert(response.data);
					
				}
			}).catch(error => {
				alert('error::'+ error);
			})
		}
	}
	
	updateWorkflow=() => {
		let wId = this.state.wId;
		let wName = this.state.wName;
		let wCategory = this.state.wCategory;
		let wSubCategory = this.state.wSubCategory;
		let notShareable = this.state.notShareable;
		let rank = this.state.wRank;
		if(wName && wCategory){
			let ApiUrl = $('#ApiUrl').val();
			let url = ApiUrl+'update-workflow/'+wId;
			let formData = new FormData();
			formData.append('name', wName);
			formData.append('category', wCategory);
			formData.append('subcategory', wSubCategory);
			formData.append('shareable', notShareable);
			formData.append('rank', rank);
			axios({
				method: 'POST',
				url: url,
				data: formData,
				headers: {
					'Content-Type': 'multipart/form-data'
				}
			})
			.then(response => {
				if(response.data){
					this.getWorkflow();
					alert(response.data);
				}
			}).catch(error => {
				alert('error::'+ error);
			})
		}
	}
	
	deleteCategory=(id) => {
		//let id = this.state.wEditCategory;
		if(id){
			let ApiUrl = $('#ApiUrl').val();
			let url = ApiUrl+'delete-workflow-category/'+id;
			//let formData = new FormData();
			//formData.append('id', id);
			axios({
				method: 'delete',
				url: url,
			})
			.then(response => {
				this.getCategories();
				this.getWorkflow();
			}).catch(error => {
				alert('error::'+ error);
			})
		}
	}
	
	getTaskList=(id,name)=>{
		let ApiUrl = $('#ApiUrl').val();
		this.setState({taskList:''});
		let url = ApiUrl+'workflow-tasks/'+id;
		let data = [];
		axios.get(url)
        .then(response => {
            data = response.data;
			let daysCount=0;
			data.map((row,i) => {
				if(row.x_days_number){
					daysCount = parseInt(daysCount)+parseInt(row.x_days_number);
				}
			})
			this.setState({taskList:data,daysCount,previewName:name});
        })
	}
	
	detailsNote=(note)=>{
		this.setState({taskNote:note});
	}
	
	wipNote=(note)=>{
		this.setState({taskWipNote:note});
	}
	
	workflowAction=(id,uid)=>{
		
		let val = 0;
		if($("input[name='"+uid+"']").prop("checked") == true){
			val = 1;
			this.setState({[uid]:true});
		}else{
			this.setState({[uid]:false});
		}
		
		this.setState({workflowActionId:id,workflowActionVal:val});
		
		if(id){
			let ApiUrl = $('#ApiUrl').val();
			let that = this;
			let url = ApiUrl+'pro-workflow/'+id;
			let data = [];
			let hasTskProgress = false;
			axios.get(url)
			.then(response => {
				data = response.data;
				if(data){
					data.map(function(job,i) {
						if(job.workflow_id == id){
							hasTskProgress = true;
							
						}
						
					})
				}
				
				if(val == 0){
					if(hasTskProgress){
						let confirmOpen = this.state.confirmOpen;
						this.setState({confirmOpen:true});
					}else{
						this.updateWorkflowAction(id,val);
					}
				}else if(val == 1){
					
					if(hasTskProgress){
						let confirmOpenActive = this.state.confirmOpenActive;
						this.setState({confirmOpenActive:true});
					}else{
						this.updateWorkflowAction(id,val);
					}
				}
				
			})
		}
	}
	
	handleClose=()=>{
		this.setState({confirmOpen:false});
	}
	
	handleCloseAgree=()=>{
		this.updateWorkflowAction(this.state.workflowActionId,this.state.workflowActionVal);
		this.setState({confirmOpen:false});
	}
	
	handleRestore=()=>{
		this.updateWorkflowAction(this.state.workflowActionId,this.state.workflowActionVal);
		this.setState({confirmOpenActive:false});
	}
	
	handleStartClean=()=>{
		this.updateWorkflowAction(this.state.workflowActionId,this.state.workflowActionVal);
		this.workflowClean(this.state.workflowActionId);
		this.setState({confirmOpenActive:false});
	}
	
	updateWorkflowAction=(id,val)=>{
		let ApiUrl = $('#ApiUrl').val();
		let url = ApiUrl+'workflow-action/'+id;
		let formData = new FormData();
		formData.append('val', val);
		axios({
			method: 'POST',
			url: url,
			data: formData,
			headers: {
				'Content-Type': 'multipart/form-data'
			}
		})
		.then(response => {
			this.getWorkflow();
		}).catch(error => {
			alert('error::'+ error);
		})
	}
	
	workflowClean=(id)=>{
		let ApiUrl = $('#ApiUrl').val();
		let url = ApiUrl+'workflow-clean/'+id;
		axios({
			method: 'GET',
			url: url,
			headers: {
				'Content-Type': 'multipart/form-data'
			}
		})
		.then(response => {
			console.log('response->',response);
		}).catch(error => {
			alert('error::'+ error);
		})
	}
	
	getQrCode=(name,uid)=>{
		this.setState({copyQrCode:uid,selectedWorkflowName:name});
	}
	
	copySharingCode=(copyText)=>{
		navigator.clipboard.writeText(copyText);
	}
	
	handleChangePage = (event: unknown, newPage: number) => {
		this.setState({page:newPage});
	};

	handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
		this.setState({rowsPerPage:event.target.value,page:0});
	};
	
	workflowInfo=(data) => {
		let created_at = moment(data.created_at).format('MM/DD/YYYY');;
		let updated_at = moment(data.updated_at).format('MM/DD/YYYY');;
		let userName = 'admin';
		let user_id = data.user_id;
		this.state.users.map(function(user,i) {
			if(user.id == user_id){
				userName = user.username;
			}
		})
		let wInfo = {
			"name": data.name,
			"created": created_at,
			"updated": updated_at,
			"createdBy": userName,
		}
		this.setState({workflowInfo:wInfo});
	}
	
	render() {
		
		const {workflowList,workflowFilter,userData,catList,catSubList,authors,filterAuthor,filterCategory,filterSubCategory,actionFilter,shareableFilter,addEditCategory,cusCategoryName,taskList,notShareable,addEditSubCategory,cusSubCategoryName} = this.state;
		//console.log('workflowList->',workflowList);
		let rowHtml = '';
		let href = window.location.href.split('?')[0];
		
		let rankOption = workflowList.map(function(val,i) {
			let pos = i+1;
			return (
				<option value={pos} key={i}>{pos}</option>
			);
		})
		
		let categoryOption = catList.map(function(val,i) {
			return (
				<option value={val.id} key={i}>{val.name}</option>
			);
		})
		
		let subcategoryOption = catSubList.map(function(val,i) {
			return (
				<option value={val.id} key={i}>{val.name}</option>
			);
		})
		
		let editCategoryOption = catList.map(function(val,i) {
			if(val.id != 1){
				return (
					<option value={val.id} key={i}>{val.name}</option>
				);
			}
		})
		
		let editSubCategoryOption = catSubList.map(function(val,i) {
			return (
				<option value={val.id} key={i}>{val.name}</option>
			);
		})
		
		let authorOption = authors.map(function(val,i) { 
			let author = '';
			userData.map(function(user,i) {
				if(user[0] == val){
					author = user[1];
				}
			})
			
			if(author){
				return (
					<option value={author} key={i}>{author}</option> 
				);
			}
		})
		
		const StyledTableCell = styled(TableCell)(({ theme }) => ({
		  [`&.${tableCellClasses.head}`]: {
			backgroundColor: '#e9e9e9',
			color: '#000;',
			fontWeight: 600,
			fontSize: 15,
		  },
		  [`&.${tableCellClasses.body}`]: {
			fontSize: 14,
		  },
		}));

		return (
			<div>
			<aside className="sidebar">
			  <div className="toggle toggle-btn">
				<a href="#" className="burger js-menu-toggle active" data-toggle="collapse" data-target="#main-navbar">
					  <span></span>
					</a>
			  </div>
			  <div className="side-inner align-items-end" >
				<div className="sidebar-content">
					<div className="nav-content">
						<h5 className="page-title">Workflow Filter</h5>
						<div className="panel-btn">
							<div className="input-group mb-3">
								<select className="form-control builder-filter" name="filterCategory" onChange={this.getValue}>
									<option value={''}>Select Category</option>
									{categoryOption}
								</select>
							</div>
							{catSubList.length > 0 ?
							<div className="input-group mb-3">
								<select className="form-control builder-filter" name="filterSubCategory" onChange={this.getValue}>
									<option value={''}>Select Subcategory</option>
									{subcategoryOption}
								</select>
							</div>
							:null}
							
							<div className="input-group mb-3">
								<select className="form-control builder-filter" name="filterAuthor" onChange={this.getValue}>
									<option value={''}>Select Author</option>
									<option value={'Admin'}>Admin</option>
									{authorOption}
								</select>
							</div>

							<div className="input-group mb-3">
								<div className="filter-checkbox"><input type="checkbox" name="actionFilter" checked={actionFilter == 1 ? 'checked' : ''} onChange={this.getValue}/> Active</div>
								<div className="filter-checkbox"><input type="checkbox" name="shareableFilter" checked={shareableFilter == 'Y' ? 'checked' : ''} onChange={this.getValue} /> Shareable</div>
							</div> 							
						</div>
						
					</div>
				</div>
			  </div>
			</aside>
			<div id="page-content-wrapper" className="workflow-manager">
				<div className="workflow-list">
					<div className="row">
						<div className="col-md-12 mb-4">
							<h4>Workflow Manager</h4>
						</div>
					</div>
					{/*<div className="wfilters">
						<div className="wc-filter">
						<label>Category:</label>
						<select className="form-control builder-filter" name="filterCategory" onChange={this.getValue}>
							<option value={''}>Select Category</option>
							{categoryOption}
						</select>
						<div><input type="checkbox" name="actionFilter" checked={actionFilter == 1 ? 'checked' : ''} onChange={this.getValue}/> Active</div>
						</div>
						<div className="wu-filter">
							<label>Author:</label>
							<select className="form-control builder-filter" name="filterAuthor" onChange={this.getValue}>
								<option value={''}>Select Author</option>
								<option value={'Admin'}>Admin</option>
								{authorOption}
							</select>
							<span><input type="checkbox" name="shareableFilter" checked={shareableFilter == 'Y' ? 'checked' : ''} onChange={this.getValue} /> Shareable</span>
						</div>
						
						 <div className="cat-manage">
							<button type="button" data-toggle="modal" data-target="#customizeCategory" className="btn btn-info float-right mr-1" data-dismiss="modal">Add/Edit/Delete Category</button>
						</div> 
					</div>*/}
				</div> 
				<Paper sx={{ width: '100%', overflow: 'hidden' }}>
				<TableContainer>
				  <Table size="small" aria-label="a dense table">
					<TableHead>
					  <TableRow>
						<StyledTableCell>Category</StyledTableCell>
						<StyledTableCell>Subategory</StyledTableCell>
						<StyledTableCell>Workflow Name</StyledTableCell>
						<StyledTableCell>Author</StyledTableCell>
						<StyledTableCell>Rank</StyledTableCell>
						<StyledTableCell>Total Days</StyledTableCell>
						<StyledTableCell>Active</StyledTableCell>
						<StyledTableCell>Shareable</StyledTableCell>
						<StyledTableCell>Info</StyledTableCell>
						<StyledTableCell>Copy</StyledTableCell>
						<StyledTableCell>Share</StyledTableCell>
						<StyledTableCell>Edit</StyledTableCell>
						<StyledTableCell>Preview</StyledTableCell>
						<StyledTableCell>&nbsp;</StyledTableCell>
					  </TableRow>
					</TableHead>
					<TableBody>
					  {workflowFilter.slice(this.state.page * this.state.rowsPerPage, this.state.page * this.state.rowsPerPage + this.state.rowsPerPage).map((row) => {
						  
						let catName = '';
						catList.map(function(cat,i) {
							if(cat.id == row.category){
								catName = cat.name;
							}
							
						})
						
						let subcatName = '';
						catSubList.map(function(cat,i) {
							if(cat.id == row.subcategory){
								subcatName = cat.name;
							}
							
						})
						
						let userName = 'Admin';
						userData.map(function(user,i) {
							if(user[0] == row.user_id){
								userName = user[1];
							}
						})
				
						return (<TableRow key={row.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
						  <TableCell>{catName}</TableCell>
						  <TableCell>{subcatName}</TableCell>
						  <TableCell>{row.name}</TableCell>
						  <TableCell>{userName}</TableCell>
						  <TableCell>{row.rank}</TableCell>
						  <TableCell>{row.total_days}</TableCell>
						  <TableCell><input type="checkbox" checked={row.action == 1 || this.state[row.uid] ? 'checked' : ''} name={row.uid} onClick={()=>this.workflowAction(row.id,row.uid)} /></TableCell>
						  <TableCell><i className={row.shareable == 'Y' ? 'fa fa-unlock' : 'fa fa-lock' } aria-hidden="true"></i></TableCell>
						  
						  <TableCell><button id={'btn'} onClick={()=>this.workflowInfo(row)} data-toggle="modal" data-target="#workflowInfo" type="button" className="btn"><img src={href+'/info-outline.png'} alt="edit" width="20" height="20"/></button></TableCell>
						  
						  <TableCell><button onClick={()=>this.copyWorkflow(row.id,row.name)} className="btn" type="button"><i className="fa fa-copy" aria-hidden="true"></i></button></TableCell>
						  <TableCell><button onClick={()=>this.getQrCode(row.name,row.uid)} data-toggle="modal" data-target="#qrCodePopup" className="btn" type="button"><img src={href+'/Icon-QR.png'} alt="OR" width="18" height="18" /></button></TableCell>
						  <TableCell><button id={'btn'} onClick={()=>this.editWorkflow(row.id,row.name,row.rank,row.category,row.subcategory,row.shareable)} data-toggle="modal" data-target="#editWorkflow" type="button" className="btn"><img src={href+'/Icon-Edit.png'} alt="edit" width="18" height="18"/></button></TableCell>
						  <TableCell><button onClick={()=>this.getTaskList(row.id,row.name)} data-toggle="modal" data-target="#previewWorkflow"className="btn" type="button"><img src={href+'/Icon-View.png'} alt="View" width="26" height="14" /></button></TableCell>
						  <TableCell><i onClick={() => { if (window.confirm('Are you sure you want to delete this workflow?')) this.deleteWorkflow(row.id) } } className="fa fa-trash"></i></TableCell>
						</TableRow>)
					  })}
					</TableBody>
				  </Table>
				</TableContainer>
				<TablePagination
					rowsPerPageOptions={[5, 10, 20, 50, 100]}
					component="div"
					count={workflowFilter.length}
					rowsPerPage={this.state.rowsPerPage}
					page={this.state.page}
					onPageChange={this.handleChangePage}
					onRowsPerPageChange={this.handleChangeRowsPerPage}
				  />
				</Paper>
				
				<div className="modal" id={"workflowInfo"} role="dialog">
					<div className="modal-dialog modal-lg custom-modal">
						<div className="modal-content">
							<div className="modal-header">
								<h5 className="modal-title">Workflow - {this.state.workflowInfo.name}</h5>
								<button type="button" className="close" data-dismiss="modal">&times;</button>
							</div>
							<div className="modal-body edit-w">
								<div className="row">
									<div className="col-4 mt-2"><b>Date Created</b></div>
									<div className="col-8 mt-2">{this.state.workflowInfo.created}</div>
									<div className="col-4 mt-2"><b>Date last Updated</b></div>
									<div className="col-8 mt-2">{this.state.workflowInfo.updated}</div>
									<div className="col-4 mt-2"><b>Created By</b></div>
									<div className="col-8 mt-2">{this.state.workflowInfo.createdBy}</div>
								 </div>
							</div>
							
						</div>
					</div>
				</div>
				
				<div className="modal" id={"editWorkflow"} role="dialog">
					<div className="modal-dialog modal-lg custom-modal">
						<div className="modal-content">
							<div className="modal-header">
								<h5 className="modal-title">Update Workflow</h5>
								<button type="button" className="close" data-dismiss="modal">&times;</button>
							</div>
							<div className="modal-body edit-w">
								<label>Workflow Name:</label>
								<input className="form-control" name="wName" value={this.state.wName} type="text" onChange={this.getValue}/>
									
								<label>Category:</label>
								<select className="form-control builder-filter" name="wCategory" value={this.state.wCategory} onChange={this.getValue}>
									{categoryOption}
								</select>
								{catSubList.length > 0 ?
								<span>
								<label>Subcategory:</label>
								<select className="form-control builder-filter" name="wSubCategory" value={this.state.wSubCategory} onChange={this.getValue}>
									<option value=''>Select Subcategory</option>
									{subcategoryOption}
								</select>
								</span>
								:null}
								<label>Rank:</label>
								<select className="form-control builder-filter" name="wRank" value={this.state.wRank} onChange={this.getValue}>
									{rankOption}
								</select>
								<div className="edit-shareable"><input type="checkbox" name="notShareable" checked={notShareable == 'N' ? 'checked' : ''} onChange={this.getValue}/> Not Shareable</div>
							</div>
							<div className="modal-footer">
								<div className="popup-btn-com">
									<button type="button" className="btn btn-danger float-right" data-dismiss="modal">Close</button>
									<button type="button" onClick={()=>this.updateWorkflow()} className="btn btn-info float-right mr-1" data-dismiss="modal">Update</button>
								</div>
							</div>
						</div>
					</div>
				</div>
				
				<div className="modal" id={"customizeCategory"} role="dialog">
					<div className="modal-dialog modal-lg custom-modal">
						<div className="modal-content">
							<div className="modal-header">
								<h5 className="modal-title">Workflow Category Manager</h5>
								<button type="button" className="close" data-dismiss="modal">&times;</button>
							</div>
							<div className="modal-body edit-w">
								
								<label>Categories:</label>
								<div>
								<select className="form-control w-edit-category" name="wEditCategory" onChange={this.getValue}>
									<option value={''}>Select Category</option>
									{editCategoryOption}
								</select>
								<span className="w-edit-span" ><button onClick={()=>this.addCategory()} type="button" className="btn"><i className="fa fa-plus"></i></button><button onClick={()=>this.editCategory()} type="button" className="btn"><img src={href+'/Icon-Edit.png'} alt="edit" width="25" height="25"/></button><button onClick={() => { if (window.confirm('Are you sure you want to delete this category?')) this.deleteCategory(this.state.wEditCategory) } } type="button" className="btn"><i className="fa fa-trash"></i></button></span>
								</div>
								{ addEditCategory ?
								<div className="new-category-input">
									<label>Add new or Edit category name:</label>
									<input className="form-control" name="cusCategoryName" value={cusCategoryName} type="text" onChange={this.getValue}/>
								</div>
								:null}
								
								{ this.state.wEditCategory ?
								<div className="new-category-input">
									<label>Sub categories for {this.state.wcatName} :</label>
									<div>
									<select className="form-control w-edit-category" name="wEditSubCategory" onChange={this.getValue}>
										<option value={''}>Select Category</option>
										{editSubCategoryOption}
									</select>
									<span className="w-edit-span" ><button onClick={()=>this.addSubCategory()} type="button" className="btn"><i className="fa fa-plus"></i></button><button onClick={()=>this.editSubCategory()} type="button" className="btn"><img src={href+'/Icon-Edit.png'} alt="edit" width="18" height="18"/></button><button onClick={() => { if (window.confirm('Are you sure you want to delete this category?')) this.deleteCategory(this.state.wEditSubCategory) } } type="button" className="btn"><i className="fa fa-trash"></i></button></span>
									</div>
									{ addEditSubCategory ?
									<div className="new-category-input">
										<label>Add or Edit Sub category name:</label>
										<input className="form-control" name="cusSubCategoryName" value={cusSubCategoryName} type="text" onChange={this.getValue}/>
									</div>
									:null}
								</div>
								:null}
							</div>
							<div className="modal-footer">
								<div className="popup-btn-com">
									<button type="button" className="btn btn-danger float-right" data-dismiss="modal">Close</button>
									<button onClick={()=>this.addUpdateCategory()} type="button" className="btn btn-info float-right mr-1" >Add&Update</button>
								</div>
							</div>
						</div>
					</div>
				</div>
				
				<div className="modal pre-w-modal" id={"previewWorkflow"} role="dialog">
					<div className="modal-dialog modal-lg preview-workflow-modal">
						<div className="modal-content">
							<div className="modal-header">
								<h5 className="modal-title">Workflow Preview</h5>
								<button type="button" className="close" data-dismiss="modal">&times;</button>
							</div>
							<div className="modal-body edit-w">
								{taskList ?
								<div className="tasks-header-div">
									<h6>{this.state.previewName} ({this.state.daysCount} Days)</h6>
									<div className="workflow-tasks-div">
										{taskList.map((value, index) => {
											//console.log('value->',value);
											let className = 'light-yellow';
											if(value.status == 'Task'){
												className = 'light-green';
											}else if(value.status == 'Subtask'){
												className = 'light-blue';
											}
											
											let icon = 'x';
											if(value.task_option == 'To Do'){
												icon = 'red';
											}else if(value.task_option == 'In Progress'){
												icon = 'yellow';
											}else if(value.task_option == 'Complete'){
												icon = 'green';
											}else if(value.task_option == 'Pending'){
												icon = 'pending';
											}
											let days = 0;
											if(value.x_days_number > 1){
												days = value.x_days_number+' Days';
											}else{
												days = value.x_days_number+' Day';
											}
											
											return(<div id={value.id} className={'task-div '+className}>
												<span className="input-title task-input-5">{value.name}</span>
												<span className="input-role">{value.role}</span>
												<span className="input-role">{value.person}</span>
												<span className="input-person">{value.due_date && value.due_date != 'null' ? moment(value.due_date).format('MM/DD/YYYY') : ''}</span>
												<span className="input-option">
												<select id={value.id} className="workflow-option" name="taskOption" >
													<option value={value.task_option}>{value.task_option}</option>
												</select>
												</span>
												<span className="input-days">{days}</span>
												<span className="task-icon"><img src={href+'/'+icon+'.png'} alt="Status" width="15" height="15" /></span>
												
												{value.completed_before == 'Y' ?
													<span className="task-icon"><img src={href+'/lock.png'} alt="Completed Before" width="15" height="15" /></span>
												:null}
												
												{value.send_message == 'Y' ?
													<span className="task-icon"><img src={href+'/send-message.png'} alt="Send Message" width="15" height="15" /></span>
												:null}
												
												{value.synchronize == 'Y' ?
													<span className="task-icon"><img src={href+'/icon-sync.png'} alt="Synchronize" width="15" height="15" /></span>
												:null}
												
												{value.details_note != null && value.details_note != '' ?
													<span className="task-icon task-note" data-toggle="modal" data-target="taskNote"><img src={href+'/icon-note.png'} alt="Details Note" width="15" height="15"/></span>
												:null}
												
												{value.wip_note != null && value.wip_note != '' ?
													<span className="task-icon task-wip-note" data-toggle="modal" data-target="taskWipNote"><img src={href+'/wip.png'} alt="WIP Note" width="15" height="15"/></span>
												:null}
												
												{value.gotolink != null && value.gotolink != '' ?
													<a href={value.gotolink} target="_blank" className="task-icon task-link-note"><img src={href+'/gotolink.png'} alt="Go to link" width="15" height="15"/></a>
												:null}
												
											</div>)
										})}
									</div>
								</div>
								:null}
							
								<div className="modal" id={"taskNote"} role="dialog">
									<div className="modal-dialog modal-lg custom-modal mds-description-modal">
										<div className="modal-content">
										  <div className="modal-header">
											<h5 className="modal-title">Details Note</h5>
											<button type="button" className="close" data-dismiss="modal">&times;</button>
										  </div>
										  <div className="modal-body">
										  {ReactHtmlParser(this.state.taskNote)}
										  </div>
										  <div className="modal-footer">
												<div className="popup-btn-com">
													<button type="button" className="btn btn-danger float-right" data-dismiss="modal">Close</button>
												</div>
										  </div>
										</div>
									</div>
								</div>
								<div className="modal" id={"taskWipNote"} role="dialog">
									<div className="modal-dialog modal-lg custom-modal mds-description-modal">
										<div className="modal-content">
										  <div className="modal-header">
											<h5 className="modal-title">WIP Note</h5>
											<button type="button" className="close" data-dismiss="modal">&times;</button>
										  </div>
										  <div className="modal-body">
										  {this.state.taskWipNote}
										  </div>
										  <div className="modal-footer">
												<div className="popup-btn-com">
													<button type="button" className="btn btn-danger float-right" data-dismiss="modal">Close</button>
												</div>
										  </div>
										</div>
									</div>
								</div>
							</div>
							<div className="modal-footer">
								<div className="popup-btn-com">
									<button type="button" className="btn btn-danger float-right" data-dismiss="modal">Close</button>
								</div>
							</div>
						</div>
					</div>
				</div>
				
				<div className="modal" id={"qrCodePopup"} role="dialog">
					<div className="modal-dialog modal-lg custom-modal qr-code-modal">
						<div className="modal-content qr-code-modal">
							<div className="modal-header">
								<button type="button" className="close" data-dismiss="modal">&times;</button>
							</div>
							<div className="modal-body">
								<img src={"https://api.qrserver.com/v1/create-qr-code/?size=150x150&data='"+this.state.copyQrCode+"'"} alt="QR" width="150" height="150" />
								<div className="qr-code-des">
									<b className="mr-3">{this.state.selectedWorkflowName}</b>
									<button onClick={()=>this.copySharingCode(this.state.copyQrCode)} className="copy-code-btn" type="button"><i className="fa fa-copy" aria-hidden="true"></i></button>
									{/* <button onClick={()=>this.saveQrImage(this.state.copyQrCode,this.state.selectedWorkflowName)} className="copy-code-btn" type="button"><i className="fa fa-download" aria-hidden="true"></i></button> */}
								</div>
							</div>
						</div>
					</div>
				</div>
				
				<Dialog
					open={this.state.confirmOpen}
					//onClose={this.handleClose}
					aria-labelledby="alert-dialog-title"
					aria-describedby="alert-dialog-description"
				>
					<DialogContent>
					  <DialogContentText id="alert-dialog-description">
						This Workflow currently has tasks in progress. By making this workflow inactive, any active tasks related to this workflow will be removed from the Workflows in Progress, and any Active tasks assigned to individuals will not be visible.
					  </DialogContentText>
					</DialogContent>
					<DialogActions>
					  <Button onClick={this.handleClose}>Disagree</Button>
					  <Button onClick={this.handleCloseAgree} autoFocus>
						Agree
					  </Button>
					</DialogActions>
				</Dialog>
				
				<Dialog
					open={this.state.confirmOpenActive}
					//onClose={this.handleRestore}
					aria-labelledby="alert-dialog-title"
					aria-describedby="alert-dialog-description"
				>
					<DialogContent>
					  <DialogContentText id="alert-dialog-description">
						This workflow was made deactivated previously while some tasks were still not completed. Should those tasks be restored to their previous state, or do you want to close all open tasks and workflows and start clean?
					  </DialogContentText>
					</DialogContent>
					<DialogActions>
					  <Button onClick={this.handleRestore}>Restore to Previous State</Button>
					  <Button onClick={this.handleStartClean} autoFocus>Start Clean</Button>
					</DialogActions>
				</Dialog>
					
			</div>	
			</div>	
		);
	}
}
