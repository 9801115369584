import React, { Component } from 'react';
import $ from 'jquery';
import 'bootstrap';
import { CSVLink } from "react-csv";
import axios,{post,get} from 'axios';
import arrayMove from "./arrayMove";
import { ulid } from 'ulid'
import moment from 'moment';
import {
  sortableContainer,
  sortableElement,
  sortableHandle,
} from 'react-sortable-hoc';

export default class TaskManager extends Component {
	
	constructor(props){
        super(props);
		this.state ={
			catList:[],
			catSubList:[],
			catSubListV2:[],
			taskListNames:[],
			taskList:[],
			tasks:[],
			csvdata:[],
			selectedListCat:'',
			selectedListCatV2:'',
			selectedListSubCat:'',
			selectedListSubCatV2:'',
			file:'',
			taskId:'',
			taskName:'',
			taskStatus:'',
			selectedList:'',
			showBottomBtn:false,
			cusCategoryName:'',
			addEditCategory:false,
			addEditSubCategory:false,
			updatePos:false,
			listOrder:false,
			listOrderFalse:true,
			fabCsvName:'Task-List-Format.csv',
		}
		this.csvLink = React.createRef();
		this.acsvLink = React.createRef();
    }
	
	componentDidMount() {
		this.getCategories();
		this.getTaskListNames();
		this.loadScript();
	}
	
	loadScript() {
		
		$(function() {

		  'use strict';

		  $('.js-menu-toggle').click(function(e) {

			var $this = $(this);

			

			if ( $('#wrapper').hasClass('toggled') ) {
				$('#wrapper').removeClass('toggled');
				$this.removeClass('active');
			} else {
				$('#wrapper').addClass('toggled');	
				$this.addClass('active');
			}

			e.preventDefault();

		  });
		});
	}
	
	getTaskListNames=()=>{
		let ApiUrl = $('#ApiUrl').val();
		let that = this;
		let url = ApiUrl+'list-name';
		let data = [];
		axios.get(url)
        .then(response => {
            data = response.data;
			this.setState({taskListNames:data});
			setTimeout(function(){
				that.getTaskList();
			}, 2000);
        })
	}
	
	getTaskList=()=>{
		let ApiUrl = $('#ApiUrl').val();
		let cat = $('#taskCt').val();
		if(cat){
			let url = ApiUrl+'tasks/'+cat;
			let data = [];
			axios.get(url)
			.then(response => {
				data = response.data;
				this.setState({taskList:data});
			})
		}
	}
	
	addListName=()=>{
		let val = $('#addCategory').val();
		let selectedListCat = this.state.selectedListCat;
		let selectedListSubCat = this.state.selectedListSubCat;
		let taskListNames = this.state.taskListNames;
		let pos = taskListNames.length;
		let ApiUrl = $('#ApiUrl').val();
		if(val){
			let url = ApiUrl+'add-list';
			let formData = new FormData();
			formData.append('category_id', selectedListCat);
			formData.append('subcategory_id', selectedListSubCat);
			formData.append('name', val);
			formData.append('pos', pos+1);;
			axios({
				method: 'POST',
				url: url,
				data: formData,
				headers: {
					'Content-Type': 'multipart/form-data'
				}
			})
			.then(response => {
				if(response.data.name){
					$('#addTaskList').modal('hide');
					this.getTaskListNames();
					alert('The list name added successfully!');
					$('#addCategory').val('');
					//$('#listCt2').val('');
					//$('#listSubCt').val('');
					//this.setState({catSubList:[]});
				}
			}).catch(error => {
				alert('error::'+ error);
			})
		}else{
			alert("Please insert the Task List Name.");
		}
	}
	
	addTask=()=>{
		let val = $('#addTaskText').val();
		//let cat = $('#taskListName').val();
		let cat = this.state.selectedList ? this.state.selectedList : $('#taskListName').val();
		let ApiUrl = $('#ApiUrl').val();
		let taskStatus = $('#taskStatus').val();
		let UID = ulid();
		if(val && cat){
			let url = ApiUrl+'add-task';
			let formData = new FormData();
			formData.append('name', val);
			formData.append('list_id', cat);
			formData.append('uid', UID);
			formData.append('status', taskStatus);
			formData.append('pos', 1);;
			axios({
				method: 'POST',
				url: url,
				data: formData,
				headers: {
					'Content-Type': 'multipart/form-data'
				}
			})
			.then(response => {
				if(response.data.name){
					this.getTaskList();
					alert('The task added successfully!');
					$('#addTaskText').val('');
					$('#taskStatus').val('Task');
					$('#addTask').modal('hide');
				}
			}).catch(error => {
				alert('error::'+ error);
			})
		}else{
			alert("Please select the list name and insert the task name.");
		}
	}
	
	changeCategory=(event)=>{
		let res = event.target.value;
		let listName = event.target.selectedOptions[0].text;
		this.getTaskList();
		if(res){
			this.setState({showBottomBtn:true,selectedList:res,selectedListName:listName,listOrder:false,listOrderFalse:true,updatePos:false});
		}else{
			this.setState({showBottomBtn:false,taskList:[],selectedList:'',selectedListName:'',updatePos:false});
		}
	}
	
	changeCategoryV2=(event)=>{
		let res = event.target.value;
		let listName = event.target.selectedOptions[0].text;
		this.getTaskList();
		if(res){
			this.setState({selectedListV2:res,selectedListNameV2:listName});
		}else{
			this.setState({selectedListV2:'',selectedListNameV2:''});
		}
	}
	
	handleOnChange=(e)=>{
		this.setState({file:e.target.files[0]});
    };
	
    downloadCsvFormat=()=>{
		const data = "Name,Details,Do_Not_Share_Details,ListName,List_Category,List_Subcategory,Type,Rank,Dependent_or_concurrent,Complex_Dependencies";
		let fileName = 'Task-List-Format.csv';
		this.setState({ csvdata:data,fabCsvName:fileName }, () => {
			let that = this;
			setTimeout(function(){ 
				that.acsvLink.current.link.click()
			}, 5000);
		})
	}
	
    handleOnSubmit=(e)=>{
        e.preventDefault();
		let file = this.state.file;
		let that = this;
		const fileReader = new FileReader();
        if (this.state.file) {
            fileReader.onload = function (event) {
                const text = event.target.result;
				that.csvFileToArray(text);
            };
            fileReader.readAsText(file);
        }
    };
	
	csvFileToArray=(string)=>{
		const csvHeader = string.slice(0, string.indexOf("\n")).split(",");
		
		//console.log('csvHeader->',csvHeader);
		
		const csvRows = string.slice(string.indexOf("\n") + 1).split("\n");
		const cat = $('#taskCt').val();
		const array = csvRows.map(i => {
			i = i.replace('\r', '');
			const UID = ulid();
			let re = /,\s*(?=(?:[^"]|"[^"]*")*$)/g; 
			const values = i.split(re);
			const obj = csvHeader.reduce((object, header, index) => {
				header = header.replace('\r', '');
				if(values[index]){
					object['Uid'] = UID;
					if(header == 'Name' || header == 'Details'){
						object[header] = values[index].replace(/^"|"$/g, '');
					}else{
						object[header] = values[index];
					}
				}
				
				//object['Category'] = cat;
				return object;
			}, {});
			return obj;
		});
		//console.log('array->',array);
		if(array.length > 0){
			this.importTask(array);
		}
	};
	
	importTask=(array)=>{
		let ApiUrl = $('#ApiUrl').val();
		let url = ApiUrl+'import-task';
		let taskData = JSON.stringify(array);
		let formData = new FormData();
			formData.append('data', taskData);
		axios({
			method: 'POST',
			url: url,
			data: formData,
			headers: {
				'Content-Type': 'multipart/form-data'
			}
		})
		.then(response => {
			if(response.data){
				$('#csvFileInput').val('');
				$('#UploadTask').modal('hide');
				alert('Task list import successfully!');
				this.setState({selectedListCat:'',selectedListSubCat:'',selectedCategoryName:'',selectedSubCategoryName:'',taskList:[]});
				this.getCategories();
				this.getTaskListNames();
			}else{
				alert('The task list already exist or Something went wrong!');
			}
		}).catch(error => {
			alert('error::'+ error);
		})
	}
	
	taskExportData = () => {
		let taskList = this.state.taskList;
		let catList = this.state.catList;
		let catSubList = this.state.catSubList;
		let currentDate = new Date();

		const data = "Name,Details,Do_Not_Share_Details,ListName,List_Category,List_Subcategory,Type,Rank,Dependent_or_concurrent,Complex_Dependencies";

		let fields = [data];
		taskList.map((task,i)=>{
			let list_category_name = '';
			let list_subcategory_name = '';
			if(task['category_id'] && catList){
				catList.map((val, i) => {
					if(val.id == task['category_id']){
						list_category_name = val.name;
					}
				})
			}
			
			if(task['subcategory_id'] && catSubList){
				catSubList.map((val, i) => {
					if(val.id == task['subcategory_id']){
						list_subcategory_name = val.name;
					}
				})
			}
			
			//fields = [task['name'],task['details'],task['share'],task['list_name'],list_category_name,list_subcategory_name,task['status'],task['pos'],task['dependent_or_concurrent'],task['dependencies']];
			
			let details = task['details'] != 'null' ? task['details'].replaceAll(',', '') : '';
			
			let str = task['name']+','+details+','+task['share']+','+task['list_name']+','+list_category_name+','+list_subcategory_name+','+task['status']+','+task['pos']+','+task['dependent_or_concurrent']+','+task['dependencies'];
			
			fields.push(str);
			
		});

		let taskstr = fields.join('\n');
		
		//console.log('taskstr->',taskstr);
		
		//let category = $('#taskCt').val();
		let lstName = $( "#taskCt option:selected" ).text();
		lstName = lstName.substring(0,20).replaceAll(' ', '-');
		//let fileName = category+'_Tasks_'+moment(currentDate).format('MM_DD_YYYY HH_MM_SS')+'.csv'
		let fileName = lstName+'-'+moment(currentDate).format('MM-DD-YYYY-MMSS')+'.csv';
		this.setState({ csvdata:taskstr, taskCsvName:fileName }, () => {
			let that = this;
			setTimeout(function(){ 
				that.csvLink.current.link.click()
			}, 3000);
		})	
	}
	
	deleteListName=()=>{
		let cat = $('#taskCt').val();
		if(cat){
			let ApiUrl = $('#ApiUrl').val();
			let url = ApiUrl+'delete-list/'+cat;
			axios({
				method: 'delete',
				url: url,
			})
			.then(response => {
				this.getTaskListNames();
				if(response.data){
					this.setState({showBottomBtn:false,taskList:[],selectedList:'',selectedListName:''});
					alert(response.data);
				}
			}).catch(error => {
				alert('error::'+ error);
			})
		}
	}
	
	deleteTask=(id)=>{
		if(id){
			let ApiUrl = $('#ApiUrl').val();
			let url = ApiUrl+'delete-task/'+id;
			let formData = new FormData();
			axios({
				method: 'delete',
				url: url,
			})
			.then(response => {
				this.getTaskList();
				if(response.data){
					alert(response.data);
				}
			}).catch(error => {
				alert('error::'+ error);
			})
		}
	}
	
	editTaskInfo=(id)=>{
		this.setState({taskId:id});
		let taskList = this.state.taskList;
		
		taskList.map((value, i) => {
			if(value.id == id){
				this.setState({taskName:value.name,taskStatus:value.status});
			}
		})
		
		$('#editTasks').modal('show');
	}
	
	getValue=(event)=>{
		let name = event.target.name;
		let res = event.target.value;
		this.setState({[event.target.name]:event.target.value});
		
		if(name == 'wEditSubCategory'){
			let selectedCategoryName = event.target.selectedOptions[0].text;
			this.setState({addEditSubCategory:false,wsubcatName:selectedCategoryName});
		}
		if(name == 'wEditCategory'){
			this.getSubCategories(res);
			let selectedCategoryName = event.target.selectedOptions[0].text;
			this.setState({addEditCategory:false,wcatName:selectedCategoryName}); 
		}
	}
	
	handleSubmit=()=>{
		let id = this.state.taskId;
		let name = this.state.taskName;
		let taskStatus = this.state.taskStatus;
		this.editTask(id,name,taskStatus);
		$('#editTasks').modal('hide');
	}
	
	editTask=(id,name,status)=>{
		let ApiUrl = $('#ApiUrl').val();
		
		if(id && name){
			let url = ApiUrl+'update-task/'+id;
			let formData = new FormData();
			formData.append('name', name);
			formData.append('status', status);
			axios({
				method: 'POST',
				url: url,
				data: formData,
				headers: {
					'Content-Type': 'multipart/form-data'
				}
			})
			.then(response => {
				if(response.data == true){
					this.getTaskList();
					alert('The task updated successfully!');
				}
			}).catch(error => {
				alert('error::'+ error);
			})
		}else{
			alert("Please select the category and insert the task name.");
		}
	}
	
	onSortEnd = ({oldIndex, newIndex}) => {
		
		this.setState(({taskList}) => ({
		    taskList: arrayMove(taskList, oldIndex, newIndex),
		}));
		
		this.setState({updatePos:true});
		/* let that = this;
		setTimeout(function(){
			let newArray = [];
			that.state.taskList.map((val, i) => {
				let j = i+1;
				newArray[i] = {'id':val.id,'pos':j};
			})
			if(newArray.length > 0){
				let ApiUrl = $('#ApiUrl').val();
				let url = ApiUrl+'update-pos';
				axios({
					method: 'POST',
					url: url,
					data: newArray,
					headers: {
						'Content-Type': 'multipart/form-data'
					}
				})
				.then(response => {
					if(response.data){
						//alert(response.data);
					}
				}).catch(error => {
					alert('error::'+ error);
				})
			}
		}, 2000); */
	};
	
	updateTaskPosition=()=>{
		let newArray = [];
		//console.log('taskList->',this.state.taskList);
		this.state.taskList.map((val, i) => {
			let j = i+1;
			newArray[i] = {'id':val.id,'pos':j};
		})
		if(newArray.length > 0){
			let ApiUrl = $('#ApiUrl').val();
			let url = ApiUrl+'update-pos';
			axios({
				method: 'POST',
				url: url,
				data: newArray,
				headers: {
					'Content-Type': 'multipart/form-data'
				}
			})
			.then(response => {
				this.setState({updatePos:false});
				alert('The Tasks order updated successfully!');
			}).catch(error => {
				alert('error::'+ error);
			})
		}
	}
	
	onSortEnd2 = ({oldIndex, newIndex}) => {

		this.setState(({taskListNames}) => ({
		    taskListNames: arrayMove(taskListNames, oldIndex, newIndex),
		}));
		
		let that = this;
		setTimeout(function(){
			let newArray = [];
			that.state.taskListNames.map((val, i) => {
				let j = i+1;
				newArray[i] = {'id':val.id,'pos':j};
			})
			
			if(newArray.length > 0){
				let ApiUrl = $('#ApiUrl').val();
				let url = ApiUrl+'update-list-pos';
				axios({
					method: 'POST',
					url: url,
					data: newArray,
					headers: {
						'Content-Type': 'multipart/form-data'
					}
				})
				.then(response => {
					alert('The Task Lists order updated successfully!');
				}).catch(error => {
					alert('error::'+ error);
				})
			}
		}, 2000);
	};
	
	getCategories=()=>{
		let ApiUrl = $('#ApiUrl').val();
		let that = this;
		let url = ApiUrl+'list-categories';
		let data = [];
		axios.get(url)
        .then(response => {
            data = response.data;
			this.setState({catList:data,selectedCategoryName:''});
        })
	}
	
	getSubCategories=(id)=>{
		let ApiUrl = $('#ApiUrl').val();
		let that = this;
		let url = ApiUrl+'list-sub-categories/'+id;
		let data = [];
		axios.get(url)
        .then(response => {
            data = response.data;
			this.setState({catSubList:data,selectedSubCategoryName:''});
        })
	}
	
	getSubCategoriesV2=(id)=>{
		let ApiUrl = $('#ApiUrl').val();
		let that = this;
		let url = ApiUrl+'list-sub-categories/'+id;
		let data = [];
		axios.get(url)
        .then(response => {
            data = response.data;
			this.setState({catSubListV2:data});
        })
	}
	
	addCategory=() => {
		this.setState({addEditCategory:true,cusCategoryName:'',cusSubCategoryName:'',editcatId:''});
	}
	
	addSubCategory=() => {
		this.setState({addEditSubCategory:true,cusSubCategoryName:'',editcatId:''});
	}
	
	editCategory=() => {
		let catName = this.state.wcatName;
		this.setState({addEditCategory:true,cusCategoryName:catName,editcatId:this.state.wEditCategory});
	}
	
	editSubCategory=() => {
		let catName = this.state.wsubcatName;
		this.setState({addEditSubCategory:true,cusSubCategoryName:catName,editcatId:this.state.wEditSubCategory});
	}
	
	addUpdateCategory=() => {
		
		let catName = this.state.cusCategoryName;
		let wEditCategory = this.state.wEditCategory;
		let catSubName = this.state.cusSubCategoryName;
		let editcatId = this.state.editcatId;
		let user_id = localStorage.getItem('user_id');
		if(catName || catSubName){
			let ApiUrl = $('#ApiUrl').val();
			let url = ApiUrl+'add-list-cat';
			let formData = new FormData();
			formData.append('id', editcatId);
			if(catSubName){
				formData.append('parent_id', wEditCategory);
				formData.append('name', catSubName);
			}else{
				formData.append('name', catName);
			}
			formData.append('user_id', user_id);
			axios({
				method: 'POST',
				url: url,
				data: formData,
				headers: {
					'Content-Type': 'multipart/form-data'
				}
			})
			.then(response => {
				if(response.data){
					if(catSubName){
						this.getSubCategories(wEditCategory);
						this.setState({wsubcatName:'',addEditSubCategory:false});
					}else{
						$('.w-edit-category').val('');
						this.getCategories();
						this.setState({cusCategoryName:'',cusSubCategoryName:'',addEditCategory:false,wcatName:'',wsubcatName:'',wEditCategory:'',addEditSubCategory:false});
					}
					//this.setState({cusCategoryName:'',cusSubCategoryName:'',addEditCategory:false,wcatName:'',wsubcatName:'',wEditCategory:'',addEditSubCategory:false});
					//$('.w-edit-category').val('');
					alert(response.data);
					
				}
			}).catch(error => {
				alert('error::'+ error);
			})
		}
	}
	
	deleteCategory=(id) => {
		//let id = this.state.wEditCategory;
		if(id){
			let ApiUrl = $('#ApiUrl').val();
			let url = ApiUrl+'delete-list-category/'+id;
			axios({
				method: 'delete',
				url: url,
			})
			.then(response => {
				if(response.data == 1){
					this.getCategories();
					this.setState({wEditCategory:'',wEditSubCategory:''});
					alert('The List category delete successfully!');
				}else{
					alert("We can't delete category unless all task Lists are Moved or Deleted from the category.");
				}
				
				
			}).catch(error => {
				alert('error::'+ error);
			})
		}else{
			alert('Please select List Category!');
		}
	}
	
	deleteSubCategory=(id) => {
		//let id = this.state.wEditCategory;
		if(id){
			let ApiUrl = $('#ApiUrl').val();
			let url = ApiUrl+'delete-list-subcategory/'+id;
			axios({
				method: 'delete',
				url: url,
			})
			.then(response => {
				if(response.data == 1){
					this.getSubCategories(this.state.wEditCategory);
					this.setState({wEditSubCategory:''});
					alert('The List subcategory delete successfully!');
				}else{
					alert("We can't delete subcategory unless all task Lists are Moved or Deleted from the category.");
				}
				
			}).catch(error => {
				alert('error::'+ error);
			})
		}else{
			alert('Please select List Category!');
		}
	}
	
	selectListCategory=(event)=>{
		let selectedCategoryName = event.target.selectedOptions[0].text;
		let res = event.target.value;
		$('#listSubCt').val('');
		this.setState({selectedListSubCat:'',selectedSubCategoryName:'',selectedList:'',selectedListName:'',taskList:[],listOrder:false});
		if(res){
			this.setState({selectedListCat:res,selectedCategoryName});
			this.getSubCategories(res);
		}else{
			this.setState({selectedListCat:'',selectedCategoryName:''});
		}
	}
	
	selectListCategoryV2=(event)=>{
		let selectedCategoryNameV2 = event.target.selectedOptions[0].text;
		let res = event.target.value;
		if(res){
			this.setState({selectedListCatV2:res,selectedCategoryNameV2});
			this.getSubCategories(res);
		}else{
			this.setState({selectedListSubCatV2:'',selectedListCatV2:'',selectedCategoryNameV2:''});
		}
	}
	
	selectListCategoryV3=(event)=>{
		let selectedCategoryNameV3 = event.target.selectedOptions[0].text;
		let res = event.target.value;
		if(res){
			this.setState({selectedListCatV3:res,selectedCategoryNameV3});
			this.getSubCategoriesV2(res);
		}else{
			this.setState({selectedListSubCatV3:'',selectedListCatV3:'',selectedCategoryNameV3:''});
		}
	}
	
	selectListSubCategory=(event)=>{
		let res = event.target.value;
		let selectedSubCategoryName = event.target.selectedOptions[0].text;
		if(res){
			this.setState({selectedListSubCat:res,selectedSubCategoryName,taskList:[],selectedListName:''});
		}else{
			this.setState({selectedListSubCat:'',selectedSubCategoryName:''});
		}
	}
	
	selectListSubCategoryV2=(event)=>{
		let res = event.target.value;
		let selectedSubCategoryNameV2 = event.target.selectedOptions[0].text;
		if(res){
			this.setState({selectedListSubCatV2:res,selectedSubCategoryNameV2});
		}else{
			this.setState({selectedListSubCatV2:'',selectedSubCategoryNameV2:''});
		}
	}
	
	selectListSubCategoryV3=(event)=>{
		let res = event.target.value;
		let selectedSubCategoryNameV3 = event.target.selectedOptions[0].text;
		if(res){
			this.setState({selectedListSubCatV3:res,selectedSubCategoryNameV3});
		}else{
			this.setState({selectedListSubCatV3:'',selectedSubCategoryNameV3:''});
		}
	}
	
	updateCategorization=()=>{
		let selectedListV2 = this.state.selectedListV2;
		let selectedListCatV3 = this.state.selectedListCatV3;
		let selectedListSubCatV3 = this.state.selectedListSubCatV3 ? this.state.selectedListSubCatV3 : 0;
		
		let ApiUrl = $('#ApiUrl').val();
		let url = ApiUrl+'update-tasks-list';
		
		let formData = new FormData();
		formData.append('Id', selectedListV2);
		formData.append('listCat', selectedListCatV3);
		formData.append('listSubCat', selectedListSubCatV3);
		axios({
			method: 'POST',
			url: url,
			data: formData,
			headers: {
				'Content-Type': 'multipart/form-data'
			}
		})
		.then(response => {
			this.getTaskListNames();
			$('#taskCategorization').modal('hide');
			//$('.unselect').val('');
			$(".unselect:selected").prop("selected", false)
			alert('The task List updated successfully!');
			this.setState({selectedList:'',selectedListName:'',taskList:[],selectedListSubCatV3:'',selectedListCatV3:'',selectedCategoryNameV3:'',selectedListSubCatV3:'',selectedSubCategoryNameV3:'',selectedListCatV2:'',selectedCategoryNameV2:'',selectedListSubCatV2:'',selectedSubCategoryNameV2:'',selectedListNameV2:''});
		}).catch(error => {
			alert('error::'+ error);
		})
		
	}
	
	taskCategoryList=()=>{
		$('#taskCt').val('')
		this.setState({listOrder:true,listOrderFalse:false});
	}
	
	render() {
		
		const {catList,catSubList,catSubListV2,taskListNames,taskList,taskCsvName,fabCsvName,showBottomBtn,addEditCategory,addEditSubCategory,cusCategoryName,selectedListCat,selectedListCatV2,selectedListSubCat,selectedListSubCatV2,selectedList,selectedListName,wcatName,wsubcatName,cusSubCategoryName,listOrder,listOrderFalse} = this.state;
		
		let catOption = taskListNames.map(function(val,i) {
			
			if(selectedListSubCat && val['category_id'] == selectedListCat && selectedListSubCat == val['subcategory_id']){
				return (
					<option value={val['id']} key={i}>{val['name']}</option>
				);
				
			}else if(selectedListSubCat == '' && val['category_id'] == selectedListCat){
				return (
					<option value={val['id']} key={i}>{val['name']}</option>
				);
			}
		})
		
		let catOptionV2 = taskListNames.map(function(val,i) {
			
			if(selectedListSubCatV2 && val['category_id'] == selectedListCatV2 && selectedListSubCatV2 == val['subcategory_id']){
				return (
					<option value={val['id']} key={i}>{val['name']}</option>
				);
			}else if(selectedListSubCatV2 == '' && val['category_id'] == selectedListCatV2 && val['subcategory_id'] < 1){
				return (
					<option value={val['id']} key={i}>{val['name']}</option>
				);
			}
		})
		
		let listNames = taskListNames.map(function(val,i) {
			return (
				<option value={val['id']} key={i}>{val['name']}</option>
			);
		})
		
		let categoryOption = catList.map(function(val,i) {
			return (
				<option value={val.id} key={i}>{val.name}</option>
			);
		})
		
		let editCategoryOption = catList.map(function(val,i) {
			return (
				<option value={val.id} key={i}>{val.name}</option>
			);
		})
		
		let editSubCategoryOption = catSubList.map(function(val,i) {
			return (
				<option value={val.id} key={i}>{val.name}</option>
			);
		})
		
		let editSubCategoryOptionV2 = catSubListV2.map(function(val,i) {
			return (
				<option value={val.id} key={i}>{val.name}</option>
			);
		})
		
		let href = window.location.href.split('?')[0];
		
		let that = this;
		
		const DragHandle = sortableHandle(() => <span className="showcase"></span>);
		
		const SortableItem = sortableElement(({value}) => {
			let text = value.name;
			let count = 45;
			let textLimit = text.slice(0, count) + (text.length > count ? "..." : "");
			
			return (<div id={value.id} className={'field-div'}><DragHandle /><span className="input-title task-input">{textLimit}</span><span className="input-status">{value.status}</span>
			<div className="edit-btns-status"><button type="button" className="btn code-dialog btn-edit" onClick={()=>this.editTaskInfo(value.id)}><img src={href+'/Icon-Edit.png'} alt="edit" width="18" height="18"/></button><button type="button" style={{color:'red'}} className="btn code-dialog btn-delete" onClick={() => { if (window.confirm('Are you sure you want to Delete this status?')) that.deleteTask(value.id)}}><i className="fa fa-trash"></i></button></div>
			</div>);
		});
		
		const DragHandle2 = sortableHandle(() => <span className="showcase"></span>);
		
		const SortableItem2 = sortableElement(({value}) => {
			//console.log('value->',value);
			let text = value.name;
			let count = 45;
			//let textLimit = text.slice(0, count) + (text.length > count ? "..." : "");
			let textLimit = text;
			
			if(selectedListSubCat && value.category_id == selectedListCat && selectedListSubCat == value.subcategory_id){
				return (<div id={value.id} className={'field-div'}><DragHandle2 /><span className="input-title task-input">{textLimit}</span><span className="input-status">{value.status}</span></div>);
			}else if(selectedListSubCat == '' && value.category_id == selectedListCat){
				return (<div id={value.id} className={'field-div'}><DragHandle2 /><span className="input-title task-input">{textLimit}</span><span className="input-status">{value.status}</span></div>);
			}else if(selectedListSubCat == '' && selectedListCat == ''){
				return (<div id={value.id} className={'field-div'}><DragHandle2 /><span className="input-title task-input">{textLimit}</span><span className="input-status">{value.status}</span></div>);
			}else{
				return (<div id={value.id} className={'field-div disablelist'}><DragHandle2 /><span className="input-title task-input">{textLimit}</span><span className="input-status">{value.status}</span></div>);
			}
			
			//return (<div id={value.id} className={'field-div'}><DragHandle2 /><span className="input-title task-input">{textLimit}</span><span className="input-status">{value.status}</span></div>);
		});
		
		const SortableContainer = sortableContainer(({children}) => {
		  return <div className="status-inner">{children}</div>;
		});
		
		return (
			<div>
			<aside className="sidebar">
			  <div className="toggle toggle-btn">
				<a href="#" className="burger js-menu-toggle active" data-toggle="collapse" data-target="#main-navbar">
					  <span></span>
					</a>
			  </div>
			  <div className="side-inner align-items-end" >
				<div className="sidebar-content">
					<div className="nav-content">
						<h5 className="page-title">Filter Task Lists</h5>
						<div className="panel-btn">
							<div className="input-group mb-3">
								<select id='listCt' className="form-control" name="listCt" value={this.state.selectedListCat} onChange={this.selectListCategory}>
								<option value="">Select List Category</option>
								{categoryOption}
								</select>
							</div>
							<div className="input-group mb-3">
								<select className="form-control" name="listSubCt" value={this.state.selectedListSubCat} onChange={this.selectListSubCategory}>
								<option value="">Select List Subcategory</option>
								{editSubCategoryOption}
								</select>
							</div> 
						</div>
						<div className="left-link-box">
							<h5 className="page-title">List Management</h5>
							<button className="btn btn-header-link" data-toggle="modal" data-target="#customizeCategory" data-dismiss="modal">Manage Task List Categories</button>
							<button className="btn btn-header-link" data-toggle="modal" data-target="#taskCategorization" data-dismiss="modal">Change Task List Categorization</button>
							<button className="btn btn-header-link" onClick={()=>this.taskCategoryList()}>Change Task List Order</button>
						</div>
						
						<div className="left-link-box">
							<h5 className="page-title">Task Management</h5>
							<button className="btn btn-header-link" data-toggle="modal" data-target="#addTaskList" data-dismiss="modal">Add List</button>
							<button className="btn btn-header-link" data-toggle="modal" data-target="#addTask" data-dismiss="modal">Add Task</button>
							<button className="btn btn-header-link" data-toggle="modal" data-target="#UploadTask" data-dismiss="modal">Upload / Download Task Lists</button>
						</div>
					</div>
				</div>
			  </div>
			</aside>
			<div id="page-content-wrapper" className="status-list">
				<div className="row">
					<div className="col-md-12 mb-4 d-flex justify-content-center">
						<h4>Task List Builder</h4>
					</div>
				</div>
				
				<div className="col-md-12">
					<div className="row">
						<div className="col-md-5 cat-heading">
						{this.state.selectedCategoryName ? 
						<span>{this.state.selectedCategoryName}</span>
						:null}
						
						{this.state.selectedSubCategoryName ? 
						<span> / {this.state.selectedSubCategoryName}</span>
						:null}
						</div>
						<div className="col-md-6">
						{selectedListCat ?
							<div className="input-group">
								<select id='taskCt' className="form-control" name="taskCt" onChange={this.changeCategory}>
								<option value="">Select Task List</option>
								{catOption}
								</select>
							</div>
						:null}
						</div>
						
					</div>
				</div>
				{listOrderFalse ? 
				<div className="col-md-12 tasks-table-div">
					{selectedListName ? <p className="task-list-label">"{selectedListName}" Task List <button className="btn" type="button" onClick={() => { if (window.confirm('Are you sure you want to Delete this list Name?')) this.deleteListName()}}><i className="fa fa-trash"></i></button></p> : ''}
					{taskList.length > 0 ?
					<SortableContainer onSortEnd={this.onSortEnd} useDragHandle>
						{taskList.map((value, index) => (
						  <SortableItem key={index} index={index} value={value} /> 
						))}
					</SortableContainer>
					:null}
				</div>
				:null}
				{this.state.updatePos ?
					<button onClick={()=>this.updateTaskPosition()} type="button" className="btn btn-primary float-right mr-1" >Update Order of Tasks</button>
				:null}
				{listOrder ? 
				<div className="col-md-12 tasks-table-div-3">
					{taskListNames.length > 0 ?
					<SortableContainer onSortEnd={this.onSortEnd2} useDragHandle>
						{taskListNames.map((value, index) => (
						  <SortableItem2 key={index} index={index} value={value} /> 
						))}
					</SortableContainer>
					:null}
				</div>
				:null}
				<div className="modal" id={"taskCategorization"} role="dialog">
					<div className="modal-dialog modal-lg custom-modal">
						<div className="modal-content">
							<div className="modal-header">
								<h5 className="modal-title">Change Task List Categorization</h5>
								<button type="button" className="close" data-dismiss="modal">&times;</button>
							</div>
							<div className="modal-body edit-w">
								<div className="panel-btn-v2">
									<div className="input-group mb-3">
										<select id='listCt' className="form-control unselect" name="listCt" value={this.state.selectedListCatV2} onChange={this.selectListCategoryV2}>
										<option value="">Select Current List Category</option>
										{categoryOption}
										</select>
									</div>
									<div className="input-group mb-3">
										<select className="form-control unselect" name="listSubCt" value={this.state.selectedListSubCatV2} onChange={this.selectListSubCategoryV2}>
										<option value="">Select Current List Subcategory</option>
										{editSubCategoryOption}
										</select>
									</div>
									{selectedListCatV2 ?
										<div className="input-group">
											<select id='taskCtV2' className="form-control unselect" name="taskCtV2" onChange={this.changeCategoryV2}>
											<option value="">Select Task List</option>
											{catOptionV2}
											</select>
										</div>
									:null}
								</div>
								<div className="change-task-cat">
									<label>
									{this.state.selectedCategoryNameV2 ? this.state.selectedCategoryNameV2 : ''} 
									
									{this.state.selectedSubCategoryNameV2 ? ' / '+this.state.selectedSubCategoryNameV2 : ''}
									
									{this.state.selectedListNameV2 ? ' / '+this.state.selectedListNameV2 : ''}
									</label>
									
								</div>
								{this.state.selectedListNameV2 ?
								<div className="panel-btn-v2">
									<h6 className="modal-title mb-3">Select New Categorization for Task List</h6>
									<div className="input-group mb-3">
										<select className="form-control unselect" name="listCtV3" onChange={this.selectListCategoryV3}>
										<option value="">Select List Category</option>
										{categoryOption}
										</select>
									</div>
									<div className="input-group mb-3">
										<select className="form-control unselect" name="listSubCtV3" onChange={this.selectListSubCategoryV3}>
										<option value="">Select List Subcategory</option>
										{editSubCategoryOptionV2}
										</select>
									</div>
									<button onClick={()=>this.updateCategorization()} type="button" className="btn btn-primary float-right mr-1" >Update</button>
								</div>
								:null}
							</div>	
						</div>
					</div>
				</div>
				
				
				<div className="modal" id={"customizeCategory"} role="dialog">
					<div className="modal-dialog modal-lg custom-modal">
						<div className="modal-content">
							<div className="modal-header">
								<h5 className="modal-title">Task List Category Manager</h5>
								<button type="button" className="close" data-dismiss="modal">&times;</button>
							</div>
							<div className="modal-body edit-w">
								
								<label>Categories:</label>
								<div>
								<select className="form-control w-edit-category" name="wEditCategory" onChange={this.getValue}>
									<option value={''}>Select Category</option>
									{editCategoryOption}
								</select>
								<span className="w-edit-span" ><button onClick={()=>this.addCategory()} type="button" className="btn"><i className="fa fa-plus"></i></button>
								
								{this.state.wEditCategory ?
								<button onClick={()=>this.editCategory()} type="button" className="btn"><img src={href+'/Icon-Edit.png'} alt="edit" width="18" height="18"/></button>
								:null}
								{this.state.wEditCategory ?
								<button onClick={() => { if (window.confirm('Are you sure you want to delete this category?')) this.deleteCategory(this.state.wEditCategory) } } type="button" className="btn"><i className="fa fa-trash"></i></button>
								:null}
								</span>
								</div>
								{ addEditCategory ?
								<div className="new-category-input">
									{/* cusCategoryName ?
									<label>Edit category name:</label>
									:
									<label>Add category name:</label>
									 */}
									<label>Add&Edit category name:</label>
									<input className="form-control" name="cusCategoryName" value={cusCategoryName} type="text" onChange={this.getValue}/>
								</div>
								:null}
								
								{ this.state.wEditCategory ?
								<div className="new-category-input">
									<label>Subcategories for {this.state.wcatName} :</label>
									<div>
									<select className="form-control w-edit-category" name="wEditSubCategory" onChange={this.getValue}>
										<option value={''}>Select Subcategory</option>
										{editSubCategoryOption}
									</select>
									<span className="w-edit-span" ><button onClick={()=>this.addSubCategory()} type="button" className="btn"><i className="fa fa-plus"></i></button>
									
									{this.state.wEditSubCategory ?
									<button onClick={()=>this.editSubCategory()} type="button" className="btn"><img src={href+'/Icon-Edit.png'} alt="edit" width="18" height="18"/></button>
									:null}
									{this.state.wEditSubCategory ?
									<button onClick={() => { if (window.confirm('Are you sure you want to delete this subcategory?')) this.deleteSubCategory(this.state.wEditSubCategory) } } type="button" className="btn"><i className="fa fa-trash"></i></button>
									:null}
									</span>
									</div>
									{ addEditSubCategory ?
									<div className="new-category-input">
										{/* cusSubCategoryName ?
										<label>Edit Subcategory name:</label>
										:
										<label>Add Subcategory name:</label>
										 */}
										<label>Add&Edit Subcategory name:</label>
										<input className="form-control" name="cusSubCategoryName" value={cusSubCategoryName} type="text" onChange={this.getValue}/>
									</div>
									:null}
								</div>
								:null}
							</div>
							<div className="modal-footer">
								<div className="popup-btn-com">
									<button type="button" className="btn btn-danger float-right" data-dismiss="modal">Close</button>
									<button onClick={()=>this.addUpdateCategory()} type="button" className="btn btn-primary float-right mr-1" >Update</button>
								</div>
							</div>
						</div>
					</div>
				</div>
				
				<div className="modal" id={'addTaskList'}>
					<div className="modal-dialog modal-lg modal-sm select-modal">
						<div className="modal-content">
							<div className="modal-header">
								<h4 className="modal-title">Add Task List</h4>
								<button type="button" className="close" data-dismiss="modal">&times;</button>
							</div>
							<div className="modal-body">
								{/* selectedListCat == '' ?
									<div>
									<label> List Category: </label>
									<select id='listCt2' className="form-control" name="listCt" value={this.state.selectedListCat} onChange={this.selectListCategory}>
									<option value="">Select List Category</option>
									{categoryOption}
									</select>
									</div>
								:null}
								{catSubList.length > 0 && selectedListSubCat == '' ?
									<div>
									<label> List Subcategory: </label>
									<select id='listSubCt' className="form-control" name="listSubCt" value={this.state.selectedListSubCat} onChange={this.selectListSubCategory}>
										<option value="">Select List Subcategory</option>
										{editSubCategoryOption}
									</select>
									</div>
								:null */}
								
								<div>
									<label> List Category: </label>
									<select id='listCt2' className="form-control" name="listCt" value={this.state.selectedListCat} onChange={this.selectListCategory}>
									<option value="">Select List Category</option>
									{categoryOption}
									</select>
								</div>
								{catSubList.length > 0 ?
									<div>
									<label> List Subcategory: </label>
									<select id='listSubCt' className="form-control" name="listSubCt" value={this.state.selectedListSubCat} onChange={this.selectListSubCategory}>
										<option value="">Select List Subcategory</option>
										{editSubCategoryOption}
									</select>
									</div>
								:null}
								
								<label> Task List Name: </label>
								<input id="addCategory" className="form-control" name="addCategory" type="text" placeholder="Add New Task List Name" />
							</div>
							<div className="modal-footer">
								<button type="button" className="btn btn-primary float-left" onClick={()=>this.addListName()}> Save </button>
								<button type="button" className="btn btn-danger" data-dismiss="modal">Close</button>
							</div>
						</div>
					</div>
				</div>
				
				<div className="modal" id={'addTask'}>
					<div className="modal-dialog modal-lg modal-sm select-modal">
						<div className="modal-content">
							<div className="modal-header">
								<h4 className="modal-title">Add Task</h4>
								<button type="button" className="close" data-dismiss="modal">&times;</button>
							</div>
							<div className="modal-body">
								{selectedList == '' ?
								<div>
								<label> Task List: </label>
								<select id='taskListName' className="form-control" name="taskListName">
								<option value="">Select Task List</option>
								{listNames}
								</select>
								</div>
								:null}
								<label> Task Status: </label>
								<select id='taskStatus' className="form-control" name="taskStatus">
									<option value="Task">Task</option>
									<option value="Subtask">Subtask</option>
									<option value="Status">Status</option>
									<option value="WIP Status">WIP Status</option>
								</select>
								
								<label> Task Name: </label>
								<input id="addTaskText" className="form-control" name="addTaskText" type="text" />
							</div>
							<div className="modal-footer">
								<button type="button" className="btn btn-primary float-left" onClick={()=>this.addTask()}> Save </button>
								<button type="button" className="btn btn-danger" data-dismiss="modal">Close</button>
							</div>
						</div>
					</div>
				</div>
				
				<div className="modal" id={'UploadTask'}>
					<div className="modal-dialog modal-lg modal-sm select-modal">
						<div className="modal-content">
							<div className="modal-header">
								<h4 className="modal-title">Upload Task by CSV</h4>
								<button type="button" className="close" data-dismiss="modal">&times;</button>
							</div>
							<div className="modal-body">
							<div className="row">
							<div className="col-md-12">
								<div className="input-group upload-box">
									<input className="form-control" type={"file"} id={"csvFileInput"} accept={".csv"} onChange={this.handleOnChange} placeholder="Add Task" />
									<div className="input-group-append">
										<button onClick={(e) => {this.handleOnSubmit(e)}} className="btn btn-primary append-btn" type="button">Upload</button>
										
									</div>
								</div>
								</div>
								<div className="col-md-12 mt-3">
									<button onClick={this.downloadCsvFormat} className="btn btn-primary" type="button">Download Blank Template</button>
									
									<CSVLink
										data={this.state.csvdata}
										headers={this.state.headers}
										filename={'Task-List-Format.csv'}
										className="hidden"
										ref={this.acsvLink}
										target="_blank" 
									/>
									
									{this.state.taskList.length > 0 ? 
									<button type="button" className="btn btn-info ml-3" onClick={this.taskExportData}>Download Current Task List CSV</button>
									:null}
									<CSVLink
										data={this.state.csvdata}
										filename={taskCsvName}
										className="hidden"
										ref={this.csvLink}
										target="_blank" 
									/>
								</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				
				<div className="modal" id={'editTasks'}>
					<div className="modal-dialog modal-lg modal-sm select-modal">
						<div className="modal-content">
							<div className="modal-header">
								<h4 className="modal-title">Edit Task</h4>
								<button type="button" className="close" data-dismiss="modal">&times;</button>
							</div>
							<div className="modal-body">
								<label> Task Name: </label>
								<input className="form-control" type="text" name='taskName' onChange={this.getValue} value={this.state.taskName} />
								<label> Status: </label>
								<select className="form-control" name='taskStatus' value={this.state.taskStatus} onChange={this.getValue}>
									<option value="Task">Task</option>
									<option value="Subtask">Subtask</option>
									<option value="Status">Status</option>								
									<option value="WIP Status">WIP Status</option>								
								</select>
							</div>
							<div className="modal-footer">
								<button type="button" className="btn btn-primary float-left" onClick={()=>this.handleSubmit()}> Save </button>
								<button type="button" className="btn btn-danger" data-dismiss="modal">Close</button>
							</div>
						</div>
					</div>
				</div>
			</div>	
			</div>	
		);
	}
}
