import React from 'react';

function filterResults(results) {
    let filteredResults = [];
    for (var i = 0; i < results.length; ++i) {
        if (i === 0) {
            filteredResults.push(results[i]);
            continue;
        }

        if (results[i].decodedText !== results[i-1].decodedText) {
            filteredResults.push(results[i]);
        }
    }
    return filteredResults;
}

/* class ResultContainerTable extends React.Component {
    render() {
        var results = filterResults(this.props.data);
        return (
            <table className={'Qrcode-result-table'}>
                <thead>
                    <tr>
                        <td>Format</td>
						<td>Decoded Text</td>
                        
                    </tr>
                </thead>
                <tbody>
                    {
                        results.map((result, i) => {
                            console.log(result);
                            return (<tr key={i}>
								<td>{result.result.format.formatName}</td>
                                <td>{result.decodedText}</td>  
                            </tr>);
                        })
                    }
                </tbody>
            </table>
        );
    }
} */

class ResultContainerPlugin extends React.Component {
    render() { 
        let results = filterResults(this.props.results);
		return (<div className='Result-container'>
				{results[0] ? 
                <div className='Result-section'>
                    <p><b>Format:</b> {results[0].result.format.formatName} <b>Result:</b> {results[0].decodedText}</p>
                </div>
				:null}
            </div>);
    }
}

export default ResultContainerPlugin;