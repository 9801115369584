import React, { Component } from 'react';
import $ from 'jquery';
import axios,{post,get} from 'axios';
import moment from 'moment';
import { ulid } from 'ulid'
import arrayMove from "./arrayMove";
import { CSVLink } from "react-csv";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Html5QrcodeScanner } from "html5-qrcode";
import Html5QrcodePlugin  from "./Html5QrcodePlugin";
import ResultContainerPlugin from './ResultContainerPlugin';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import {stateFromHTML} from 'draft-js-import-html';
import {convertToRaw, EditorState, convertFromHTML, convertFromRaw,ContentState} from 'draft-js';

import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import { Button, Table, TableBody, TableContainer, TableHead, TableRow } from '@mui/material';
import Paper from '@mui/material/Paper';
import TablePagination from '@mui/material/TablePagination';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';

import {
  sortableContainer,
  sortableElement,
  sortableHandle,
} from 'react-sortable-hoc';


export default class goToManager extends Component {
	
	constructor(props){
        super(props)
		this.state ={
			gotolinks:[],
			gotolinksFilter:[],
			allCategories:[],
			keyNames:new Map(),
			selectedKeyNames:'',
			categories:[],
			subcategories:[],
			tags:[],
			cusCategoryName:'',
			addEditCategory:false,
			addEditSubCategory:false,
			urltype:'Static',
			gtLinkTypeSyntax:'',
			page:0,
			rowsPerPage:10,
			filterTags:'',
		}
    }
	
	componentDidMount() {
		this.loadScript();
		this.getAllCategories();
		this.getAllGotoLinks();
		this.getCategories();
	}
	
	loadScript() {
		$(function() {
		  'use strict';
		  $('.js-menu-toggle').click(function(e) {
			var $this = $(this);
			if ( $('#wrapper').hasClass('toggled') ) {
				$('#wrapper').removeClass('toggled');
				$this.removeClass('active');
			} else {
				$('#wrapper').addClass('toggled');	
				$this.addClass('active');
			}
			e.preventDefault();
		  });
		});
	}
	
	getValue=(event)=>{
		let name = event.target.name;
		let res = event.target.value;
		this.setState({[event.target.name]:event.target.value});
		
		if(name == 'wEditSubCategory'){
			let selectedCategoryName = event.target.selectedOptions[0].text;
			this.setState({addEditSubCategory:false,wsubcatName:selectedCategoryName});
		}
		if(name == 'wEditCategory'){
			this.getSubCategories(res);
			let selectedCategoryName = event.target.selectedOptions[0].text;
			this.setState({addEditCategory:false,wcatName:selectedCategoryName}); 
		}
		
		if(name == 'filterCategory' || name == 'addLinkCategory' || name == 'egtLinkCategory'){
			this.getSubCategories(res);
		}
		
		if(name == 'filterCategory' || name == 'filterSubCategory' || name == 'filterTags' || name == 'filterType'){
			let that = this;
			setTimeout(function(){
				that.dataFilter();
			}, 1000);
		}
		
		if(name == 'filterCategory'){
			this.setState({addLinkCategory:res});
		}
		
		if(name == 'filterSubCategory'){
			this.setState({addLinkSubcategory:res});
		}
		
		let that = this;
		if(name == 'gtLinkBaseUrl'){
			$("input[name='gtLinkTypeSyntax']").val(res);
			/* setTimeout(function(){
				that.setLinkSyntaxUrl();
			}, 1000); */
		}
		
		if(name == 'urltype'){
			//alert(res);
		}
	}
	
	getAllGotoLinks=()=>{
		let ApiUrl = $('#ApiUrl').val();
		let that = this;
		let url = ApiUrl+'gotolinks';
		let data = [];
		axios.get(url)
        .then(response => {
            data = response.data;
			
			this.setState({gotolinks:data});
			
			setTimeout(function(){
				that.dataFilter();
			}, 1000);
        })
	}
	
	getAllCategories=()=>{
		let ApiUrl = $('#ApiUrl').val();
		let that = this;
		let url = ApiUrl+'all-subcategories';
		let data = [];
        axios.get(url)
        .then(response => {
            data = response.data;
			this.setState({allCategories:data});
        })
	}
	
	getCategories=()=>{
		let ApiUrl = $('#ApiUrl').val();
		let that = this;
		let url = ApiUrl+'gotolink-categories';
		let data = [];
        axios.get(url)
        .then(response => {
            data = response.data;
			this.setState({categories:data});
        })
	}
	
	getSubCategories=(id)=>{
		let ApiUrl = $('#ApiUrl').val();
		let that = this;
		let url = ApiUrl+'gotolink-subcategories/'+id;
		let data = [];
		axios.get(url)
        .then(response => {
            data = response.data;
			this.setState({subcategories:data,egtLinkSubcategory:0});
        })
	}
	
	dataFilter=() => {
		let gotolinksFilter = [];
		let that = this;
		let tags = [];
		this.state.gotolinks.map(function(row,i) {
			
			tags.push(row.tags);
			
			if(that.state.filterType && that.state.filterType != row.type){
				return false;
			}

			if(that.state.filterCategory && that.state.filterCategory != row.category){
				return false;
			}
			
			if(that.state.filterSubCategory && that.state.filterSubCategory != row.subcategory){
				return false;
			}
			
			if(that.state.filterTags && that.state.filterTags != row.tags){
				return false;
			}

			gotolinksFilter.push(row);
		});
		this.setState({gotolinksFilter,page:0,tags});
	}
	
	addCategory=() => {
		this.setState({addEditCategory:true,cusCategoryName:'',cusSubCategoryName:'',editcatId:''});
	}
	
	addSubCategory=() => {
		this.setState({addEditSubCategory:true,cusSubCategoryName:'',editcatId:''});
	}
	
	editCategory=() => {
		let catName = this.state.wcatName;
		this.setState({addEditCategory:true,cusCategoryName:catName,editcatId:this.state.wEditCategory});
	}
	
	editSubCategory=() => {
		let catName = this.state.wsubcatName;
		this.setState({addEditSubCategory:true,cusSubCategoryName:catName,editcatId:this.state.wEditSubCategory});
	}
	
	addUpdateCategory=() => {
		
		let catName = this.state.cusCategoryName;
		let wEditCategory = this.state.wEditCategory;
		let catSubName = this.state.cusSubCategoryName;
		let editcatId = this.state.editcatId;
		if(catName || catSubName){
			let ApiUrl = $('#ApiUrl').val();
			let url = ApiUrl+'add-gotolink-category';
			let formData = new FormData();
			formData.append('id', editcatId);
			if(catSubName){
				formData.append('parent_id', wEditCategory);
				formData.append('name', catSubName);
			}else{
				formData.append('name', catName);
			}
			axios({
				method: 'POST',
				url: url,
				data: formData,
				headers: {
					'Content-Type': 'multipart/form-data'
				}
			})
			.then(response => {
				if(response.data){
					if(catSubName){
						this.getSubCategories(wEditCategory);
						this.setState({wsubcatName:'',addEditSubCategory:false});
					}else{
						$('.w-edit-category').val('');
						this.getCategories();
						this.setState({cusCategoryName:'',cusSubCategoryName:'',addEditCategory:false,wcatName:'',wsubcatName:'',wEditCategory:'',addEditSubCategory:false});
					}
					alert(response.data);
					
				}
			}).catch(error => {
				alert('error::'+ error);
			})
		}
	}
	
	handleChangePage = (event: unknown, newPage: number) => {
		this.setState({page:newPage});
	};

	handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
		this.setState({rowsPerPage:event.target.value,page:0});
	};
	
	addGotoLink=() => {
		
		let addLinkCategory = this.state.addLinkCategory ? this.state.addLinkCategory : 1;
		let addLinkSubcategory = this.state.addLinkSubcategory ? this.state.addLinkSubcategory : 0;
		let gtLinkName = this.state.gtLinkName;
		let gtLinkDes = this.state.gtLinkDes ? this.state.gtLinkDes : '';
		let gtLinkType = this.state.urltype ? this.state.urltype : 'static';
		let gtLinkTags = this.state.gtLinkTags ? this.state.gtLinkTags : '';
		let gtLinkBaseUrl = this.state.gtLinkBaseUrl ? this.state.gtLinkBaseUrl : '';
		let gtLinkUrl = gtLinkType == "Static" ? this.state.gtLinkUrl : this.state.gtLinkTypeSyntax;
		let selectedKeyNames = this.state.selectedKeyNames;
		let user_facility_id = localStorage.getItem('user_facility_id');

		if(addLinkCategory && gtLinkName && gtLinkUrl){
			
			let ApiUrl = $('#ApiUrl').val();
			let url = ApiUrl+'add-gotolink';
			let uid = ulid();
			let formData = new FormData();
			formData.append('uid', uid);
			formData.append('facility_id', user_facility_id);
			formData.append('category', addLinkCategory);
			formData.append('subcategory', addLinkSubcategory);
			formData.append('name', gtLinkName);
			formData.append('description', gtLinkDes);
			formData.append('type', gtLinkType);
			formData.append('tags', gtLinkTags);
			formData.append('base_url', gtLinkBaseUrl);
			formData.append('keyname', selectedKeyNames);
			formData.append('url', gtLinkUrl);
			//formData.append('index1', gtLinkIndexID1);
			//formData.append('index2', gtLinkIndexID2);
			//formData.append('index3', gtLinkIndexID3);
			//formData.append('anchor_index', gtLinkAnchorIndexID);
			formData.append('active', 1);
			axios({
				method: 'POST',
				url: url,
				data: formData,
				headers: {
					'Content-Type': 'multipart/form-data'
				}
			})
			.then(response => {
				if(response.data){
					this.getAllGotoLinks();
					this.setState({addLinkCategory:'',addLinkSubcategory:'',gtLinkName:'',gtLinkDes:'',gtLinkType:'',gtLinkTags:'',gtLinkUrl:'',urltype:'Static',keyNames:new Map(),selectedKeyNames:''});
					alert(response.data);
					$('#addGotoLink').modal('hide');
					$("input[name='gtLinkName']").val('');
					$("input[name='gtLinkDes']").val('');
					$("input[name='gtLinkTags']").val('');
					$("input[name='gtLinkUrl']").val('');
				}
			}).catch(error => {
				alert('error::'+ error);
			})
		}else{
			alert("Please insert Goto link data correctly!");
		}
	}
	
	editGotoLink=(val) => {
		this.setState({keyNames:new Map()});
		let egtgotoLinkId = val.id;
		let egtLinkCategory = val.category;
		let egtLinkSubcategory = val.subcategory;
		let egtLinkName = val.name;
		let egtLinkDes = val.description ? val.description : '';
		let eurltype = val.type ? val.type : 'Static';
		let egtLinkTags = val.tags ? val.tags : '';
		let egtLinkUrl = val.url;
		let egtLinkActive = val.active;
		if(egtLinkCategory != ''){
			this.getSubCategories(egtLinkCategory);
		}
		let gtLinkBaseUrl = val.base_url;
		let gtLinkTypeSyntax = val.url;
		//console.log('val->',val);
		if(val.type == 'Dynamic'){
			
			let selectedKeyNames = val.keyname;
			let keys = val.keyname.split(',');
			let that = this;
			setTimeout(function(){
				let keyNameArr = that.state.keyNames;
				if(keys.length > 0){
					keys.map((val, index) => {
						keyNameArr.set(val,val);
						$("input[name='"+val+"']").prop("checked", true);
					})
				}
				that.setState({keyNames:keyNameArr,gtLinkBaseUrl,gtLinkTypeSyntax});
			}, 1000);
		}
		
		this.setState({egtgotoLinkId,egtLinkCategory,egtLinkSubcategory,egtLinkName,egtLinkDes,eurltype,egtLinkTags,egtLinkUrl,egtLinkActive,gtLinkBaseUrl,gtLinkTypeSyntax});
		
		$('#editGotoLink').modal('show');
	}
	
	updateGotoLink=() => {
		
		let egtgotoLinkId = this.state.egtgotoLinkId;
		let egtLinkCategory = this.state.egtLinkCategory;
		let egtLinkSubcategory = this.state.egtLinkSubcategory;
		let egtLinkName = this.state.egtLinkName;
		let egtLinkDes = this.state.egtLinkDes;
		let egtLinkType = this.state.eurltype ? this.state.eurltype : 'static';
		let egtLinkTags = this.state.egtLinkTags;
		
		let gtLinkBaseUrl = this.state.gtLinkBaseUrl ? this.state.gtLinkBaseUrl : '';
		let egtLinkUrl = egtLinkType == "Static" ? this.state.egtLinkUrl : this.state.gtLinkTypeSyntax;

		let egtLinkActive = this.state.egtLinkActive;
		let selectedKeyNames = this.state.selectedKeyNames;
		if(egtgotoLinkId && egtLinkName && egtLinkUrl){
			
			let ApiUrl = $('#ApiUrl').val();
			let url = ApiUrl+'edit-gotolink';
			let uid = ulid();
			let formData = new FormData();
			formData.append('id', egtgotoLinkId);
			formData.append('category', egtLinkCategory);
			formData.append('subcategory', egtLinkSubcategory);
			formData.append('name', egtLinkName);
			formData.append('description', egtLinkDes);
			formData.append('type', egtLinkType);
			formData.append('tags', egtLinkTags);
			formData.append('base_url', gtLinkBaseUrl);
			formData.append('keyname', selectedKeyNames);
			formData.append('url', egtLinkUrl);

			formData.append('active', egtLinkActive);
			axios({
				method: 'POST',
				url: url,
				data: formData,
				headers: {
					'Content-Type': 'multipart/form-data'
				}
			})
			.then(response => {
				if(response.data){
					this.getAllGotoLinks();
					this.setState({egtgotoLinkId:'',egtLinkCategory:'',egtLinkSubcategory:'',egtLinkName:'',egtLinkDes:'',egtLinkType:'',egtLinkTags:'',egtLinkUrl:'',egtLinkActive:'',selectedKeyNames:'',keyNames:new Map(),gtLinkBaseUrl:''});
					alert(response.data);
					$('#editGotoLink').modal('hide');
				}
			}).catch(error => {
				alert('error::'+ error);
			})
		}else{
			alert("Please insert Goto link data correctly!");
		}
	}
	
	copyGuidCode=(copyText)=>{
		navigator.clipboard.writeText(copyText);
	}
	
	deleteGotolink=(id)=>{
		if(id){
			let ApiUrl = $('#ApiUrl').val();
			let url = ApiUrl+'delete-gotolink/'+id;
			axios({
				method: 'delete',
				url: url,
			})
			.then(response => {
				this.getAllGotoLinks();
			}).catch(error => {
				alert('error::'+ error);
			})
		}
	}

	/* getGotoLinkType=()=>{
		let ApiUrl = $('#ApiUrl').val();
		let that = this;
		let url = ApiUrl+'gotolink-types';
		let data = [];
		axios.get(url)
        .then(response => {
            data = response.data;
			this.setState({gotolinkTypes:data});
        })
	} */
	
	selectKeyNames=(event)=>{
		let name = event.target.name;
		let keyNameArr = this.state.keyNames;
		if($("input[name='"+name+"']").prop("checked") == true){
			let urlstr = $("input[name='gtLinkTypeSyntax']").val();
			
			$("input[name='gtLinkTypeSyntax']").val(urlstr+'/'+'{'+name+'}');
			
			let gtLinkTypeSyntax = urlstr+'/'+'{'+name+'}';
			
			this.setState({gtLinkTypeSyntax});

			keyNameArr.set(name,name);
			
		}else if($("input[name='"+name+"']").prop("checked") == false){
			keyNameArr.delete(name);
		}
		
		this.setState({keyNames:keyNameArr});
		
		let that = this;
		setTimeout(function(){
			that.setLinkSyntaxUrl();
		}, 1000); 
	}
	
	setLinkSyntaxUrl=()=>{
		let keyNameArr = this.state.keyNames;
		if(keyNameArr){
			let keyNameArrs = Object.fromEntries(keyNameArr.entries());
			let calArr = [];
			for (let prop in keyNameArrs) {
				calArr.push(keyNameArrs[prop]);
			}
			calArr = calArr.filter(function(x) {
				 return x !== '';
			});
			
			keyNameArr = calArr.join(',');
			
		}
		
		this.setState({selectedKeyNames:keyNameArr});
	}
	
	/* setLinkSyntaxUrl=()=>{
		let keyNameArr = this.state.keyNames;
		let gtLinkBaseUrl = this.state.gtLinkBaseUrl ? this.state.gtLinkBaseUrl : 'https://example.com/';
		let syntaxParms = '';
		if(keyNameArr){
			let keyNameArrs = Object.fromEntries(keyNameArr.entries());
			let calArr = [];
			for (let prop in keyNameArrs) {
				calArr.push(keyNameArrs[prop]);
			}
			calArr = calArr.filter(function(x) {
				 return x !== '';
			});
			
			keyNameArr = calArr.join('/');
			
			let strUrl = [];
			if(calArr.length > 0){
				calArr.map((val, index) => {
					let ur = '{'+val+'}';
					strUrl.push(ur);
				})
			}
			syntaxParms = strUrl.join('/');
		}
		
		let syntax = gtLinkBaseUrl+'/'+syntaxParms;
		this.setState({selectedKeyNames:keyNameArr,gtLinkTypeSyntax:syntax});
		$("input[name='gtLinkTypeSyntax']").val(syntax);
	} */
	
	deleteCategory=(id) => {
		//let id = this.state.wEditCategory;
		if(id){
			let ApiUrl = $('#ApiUrl').val();
			let url = ApiUrl+'delete-goto-category/'+id;
			axios({
				method: 'delete',
				url: url,
			})
			.then(response => {
				if(response.data == 1){
					this.getCategories();
					alert('The category deleted successfully!');
				}else{
					alert("We can't delete category unless all Go-To link are Moved or Deleted from the category.");
				}
			}).catch(error => {
				alert('error::'+ error);
			})
		}
	}
	
	deleteSubcategory=(id) => {
		if(id){
			let ApiUrl = $('#ApiUrl').val();
			let url = ApiUrl+'delete-goto-subcategory/'+id;
			axios({
				method: 'delete',
				url: url,
			})
			.then(response => {
				if(response.data == 1){
					this.getSubCategories(this.state.wEditCategory);
					alert('The subcategory deleted successfully!');
				}else{
					alert("We can't delete subcategory unless all Go-To link are Moved or Deleted from the category.");
				}
			}).catch(error => {
				alert('error::'+ error);
			})
		}
	}
	
	render() {

		const {gotolinks,gotolinksFilter,allCategories,categories,subcategories,addEditCategory,addEditSubCategory,cusCategoryName,cusSubCategoryName,keyNames,tags,filterTags} = this.state;
		
		let href = window.location.href.split('?')[0];
		
		console.log('filterTags->',filterTags);
		
		let editCategoryOption = categories.map(function(val,i) {
			return (
				<option value={val.id} key={i}>{val.name}</option>
			);
		})
		
		let editSubCategoryOption = subcategories.map(function(val,i) {
			return (
				<option value={val.id} key={i}>{val.name}</option>
			);
		})
		
		let tagsOption = tags.map(function(val,i) {
			return (
				<option value={val} key={i}>{val}</option>
			);
		})
		
		
		const StyledTableCell = styled(TableCell)(({ theme }) => ({
		  [`&.${tableCellClasses.head}`]: {
			backgroundColor: '#e9e9e9',
			color: '#000;',
			fontWeight: 600,
			fontSize: 15,
		  },
		  [`&.${tableCellClasses.body}`]: {
			fontSize: 14,
		  },
		}));
		
		return (
			<div>
				<aside className="sidebar">
				  <div className="toggle toggle-btn">
					<a href="#" className="burger js-menu-toggle active" data-toggle="collapse" data-target="#main-navbar">
						<span></span>
					</a>
				  </div>
				  <div className="side-inner align-items-end" >
					<div className="sidebar-content">
						<div className="nav-content">
							<h5 className="page-title">Go-to Link Filter</h5>
							<div className="panel-btn">
							
								<div className="input-group mb-3">
									<select className="form-control builder-filter" name="filterType" onChange={this.getValue}>
										<option value={''}>Select Link Type</option>
										<option value={'Static'}>Static</option>
										<option value={'Dynamic'}>Dynamic</option>
										
									</select>
								</div>
								
								<div className="input-group mb-3">
									<select className="form-control builder-filter" name="filterCategory" onChange={this.getValue}>
										<option value={''}>Select Category</option>
										{editCategoryOption}
									</select>
								</div>
								
								{subcategories.length > 0 ?
								<div className="input-group mb-3">
									<select className="form-control builder-filter" name="filterSubCategory" onChange={this.getValue}>
										<option value={''}>Select Subcategory</option>
										{editSubCategoryOption}
									</select>
								</div>
								:null}
								
								{tags.length > 0 ?
								<div className="input-group mb-3">
									<select className="form-control builder-filter" name="filterTags" onChange={this.getValue}>
										<option value={''}>Select Tag</option>
										{tagsOption}
									</select>
								</div>
								:null}
														
							</div>
							
							<div className="left-link-box">
								<h5 className="page-title">Go-to Link Management</h5>
								<button className="btn btn-header-link" data-toggle="modal" data-target="#customizeCategory" data-dismiss="modal">Manage Categories</button>
								
								<button className="btn btn-header-link" data-toggle="modal" data-target="#addGotoLink" data-dismiss="modal">Add New Goto Link</button>
								
							</div>

						</div>
					</div>
				  </div>
				</aside>
				
				<div id="page-content-wrapper" className="workflow-manager">
					<div className="workflow-list">
						<div className="row">
							<div className="col-md-12 mb-4">
								<h4>Go-To Link Manager</h4>
							</div>
						</div>
						<Paper sx={{ width: '100%', overflow: 'hidden' }}>
							<TableContainer>
							  <Table size="small" aria-label="a dense table">
								<TableHead>
								  <TableRow>
									<StyledTableCell>Category</StyledTableCell>
									<StyledTableCell>Subategory</StyledTableCell>
									<StyledTableCell>Name</StyledTableCell>
									<StyledTableCell>description</StyledTableCell>
									<StyledTableCell>Type</StyledTableCell>
									<StyledTableCell>Tags</StyledTableCell>
									
									<StyledTableCell>URL</StyledTableCell>
									<StyledTableCell>&nbsp;</StyledTableCell>
									
								  </TableRow>
								</TableHead>
								<TableBody>
								  {gotolinksFilter.slice(this.state.page * this.state.rowsPerPage, this.state.page * this.state.rowsPerPage + this.state.rowsPerPage).map((row) => {
									let catName = '';
									allCategories.map(function(cat,i) {
										if(cat.id == row.category){
											catName = cat.name;
										}
										
									})
									
									let subcatName = '';
									allCategories.map(function(cat,i) {
										if(cat.id == row.subcategory){
											subcatName = cat.name;
										}
										
									})
							
									return (<TableRow key={row.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
									  <TableCell className="gt-cat">{catName}</TableCell>
									  <TableCell className="gt-cat">{subcatName}</TableCell>
									  <TableCell className="gt-name">{row.name}</TableCell>
									  <TableCell className="gt-description"><p>{row.description}</p></TableCell>
									  <TableCell className="gt-type">{row.type}</TableCell>
									  <TableCell>{row.tags}</TableCell>
									 
									  <TableCell>{row.url}</TableCell>
									  <TableCell>
									  <div className="d-flex">
									  <button onClick={()=>this.editGotoLink(row)} type="button" className="btn"><img src={href+'/Icon-Edit.png'} alt="edit" width="18" height="18"/></button>
									  <button className="btn copy-code-btn" type="button" onClick={()=>this.copyGuidCode(row.uid)}><i className="fa fa-copy" aria-hidden="true"></i></button>
									  <button onClick={() => { if (window.confirm('Are you sure you want to delete this Goto Link?')) this.deleteGotolink(row.id) } } type="button" className="btn"><i className="fa fa-trash"></i></button>
									  </div>
									  </TableCell>
									  
									</TableRow>)
								  })}
								</TableBody>
							  </Table>
							</TableContainer>
							<TablePagination
								rowsPerPageOptions={[5, 10, 20, 50, 100]}
								component="div"
								count={gotolinksFilter.length}
								rowsPerPage={this.state.rowsPerPage}
								page={this.state.page}
								onPageChange={this.handleChangePage}
								onRowsPerPageChange={this.handleChangeRowsPerPage}
							  />
						</Paper>
					</div>
				</div>
				
				<div className="modal" id={"customizeCategory"} role="dialog">
					<div className="modal-dialog modal-lg custom-modal">
						<div className="modal-content">
							<div className="modal-header">
								<h5 className="modal-title">Go To Link Category Manager</h5>
								<button type="button" className="close" data-dismiss="modal">&times;</button>
							</div>
							<div className="modal-body edit-w">
								
								<label>Categories:</label>
								<div>
								<select className="form-control w-edit-category" name="wEditCategory" onChange={this.getValue}>
									<option value={''}>Select Category</option>
									{editCategoryOption}
								</select>
								<span className="w-edit-span" ><button onClick={()=>this.addCategory()} type="button" className="btn"><i className="fa fa-plus"></i></button>
								
								{this.state.wEditCategory ?
								<button onClick={()=>this.editCategory()} type="button" className="btn"><img src={href+'/Icon-Edit.png'} alt="edit" width="18" height="18"/></button>
								:null}
								{this.state.wEditCategory ?
								<button onClick={() => { if (window.confirm('Are you sure you want to delete this category?')) this.deleteCategory(this.state.wEditCategory) } } type="button" className="btn"><i className="fa fa-trash"></i></button>
								:null}
								</span>
								</div>
								{ addEditCategory ?
								<div className="new-category-input">
									{/* cusCategoryName ?
									<label>Edit category name:</label>
									:
									<label>Add category name:</label>
									 */}
									<label>Add&Edit category name:</label>
									<input className="form-control" name="cusCategoryName" value={cusCategoryName} type="text" onChange={this.getValue}/>
								</div>
								:null}
								
								{ this.state.wEditCategory ?
								<div className="new-category-input">
									<label>Subcategories for {this.state.wcatName} :</label>
									<div>
									<select className="form-control w-edit-category" name="wEditSubCategory" onChange={this.getValue}>
										<option value={''}>Select Subcategory</option>
										{editSubCategoryOption}
									</select>
									<span className="w-edit-span" ><button onClick={()=>this.addSubCategory()} type="button" className="btn"><i className="fa fa-plus"></i></button>
									
									{this.state.wEditSubCategory ?
									<button onClick={()=>this.editSubCategory()} type="button" className="btn"><img src={href+'/Icon-Edit.png'} alt="edit" width="18" height="18"/></button>
									:null}
									{this.state.wEditSubCategory ?
									<button onClick={() => { if (window.confirm('Are you sure you want to delete this subcategory?')) this.deleteSubcategory(this.state.wEditSubCategory) } } type="button" className="btn"><i className="fa fa-trash"></i></button>
									:null}
									</span>
									</div>
									{ addEditSubCategory ?
									<div className="new-category-input">
										{/* cusSubCategoryName ?
										<label>Edit Subcategory name:</label>
										:
										<label>Add Subcategory name:</label>
										 */}
										<label>Add&Edit Subcategory name:</label>
										<input className="form-control" name="cusSubCategoryName" value={cusSubCategoryName} type="text" onChange={this.getValue}/>
									</div>
									:null}
								</div>
								:null}
							</div>
							<div className="modal-footer">
								<div className="popup-btn-com">
									<button type="button" className="btn btn-danger float-right" data-dismiss="modal">Close</button>
									<button onClick={()=>this.addUpdateCategory()} type="button" className="btn btn-primary float-right mr-1" >Update</button>
								</div>
							</div>
						</div>
					</div>
				</div>
				
				<div className="modal" id={'addGotoLink'}>
					<div className="modal-dialog modal-lg modal-sm select-modal">
						<div className="modal-content">
							<div className="modal-header">
								<h4 className="modal-title">Add Goto Link</h4>
								<button type="button" className="close" data-dismiss="modal">&times;</button>
							</div>
							<div className="modal-body">
								<label> Go-To Link: </label>
								<div className="goto-radio">
									<div className="btn-goto-radio"><input type="checkbox" value="Static"  name="urltype" checked={this.state.urltype === 'Static'} onChange={this.getValue} /> Static</div>
									<div className="btn-goto-radio"><input type="checkbox" value="Dynamic"  name="urltype" checked={this.state.urltype === 'Dynamic'} onChange={this.getValue} /> Dynamic</div>
								</div>
								<label> Category: </label>
								<select className="form-control w-edit-category" name="addLinkCategory" value={this.state.addLinkCategory} onChange={this.getValue}>
									<option value={''}>Select Category</option>
									{editCategoryOption}
								</select>
								
								<label> Subcategory: </label>
								<select className="form-control w-edit-category" name="addLinkSubcategory" value={this.state.addLinkSubcategory} onChange={this.getValue}>
									<option value={''}>Select Category</option>
									{editSubCategoryOption}
								</select>
								
								<label> Name: </label>
								<input className="form-control" name="gtLinkName" type="text" placeholder="Name" onChange={this.getValue}/>
								
								<label>Description: </label>
								<input className="form-control" name="gtLinkDes" type="text" placeholder="Description" onChange={this.getValue}/>
								{this.state.urltype === 'Static' ? 
								<div className="static-sec"><label>Static URL: </label>
								<input className="form-control" name="gtLinkUrl" type="text" placeholder="URL" onChange={this.getValue}/>
								</div>
								:
								<div className="dynamic-sec">
									<label>Base URL: </label>
									<input className="form-control mb-2" name="gtLinkBaseUrl" type="text" placeholder="Base URL" onChange={this.getValue}/>
									
									<label>Add a keyname to the Go To Link Syntax: </label>
									<div className={'type-checkbox row mb-2'}>
										<div className="col-md-4"><input type="checkbox" name="FacilityID" onChange={this.selectKeyNames}/> FacilityID </div>
										<div className="col-md-4"><input type="checkbox" name="PatientBranchID" onChange={this.selectKeyNames}/> PatientBranchID</div>
										
										<div className="col-md-4"> <input type="checkbox" name="BranchID" onChange={this.selectKeyNames}/> BranchID</div>
										
										<div className="col-md-4"> <input type="checkbox" name="PatientID" onChange={this.selectKeyNames}/> PatientID</div>
										<div className="col-md-4"> <input type="checkbox" name="PatientFirstName" onChange={this.selectKeyNames}/> PatientFirstName</div>
										<div className="col-md-4"> <input type="checkbox" name="PatientLastName" onChange={this.selectKeyNames}/> PatientLastName</div>
										
										<div className="col-md-4"> <input type="checkbox" name="TreatingPractitionerID" onChange={this.selectKeyNames}/> TreatingPractitionerID</div>
										<div className="col-md-4"> <input type="checkbox" name="InsuranceCompany" onChange={this.selectKeyNames}/> InsuranceCompany</div>
										<div className="col-md-4"> <input type="checkbox" name="RxID" onChange={this.selectKeyNames}/> RxID</div>
										
										<div className="col-md-4"> <input type="checkbox" name="ClaimID" onChange={this.selectKeyNames}/> ClaimID</div>
										<div className="col-md-4"> <input type="checkbox" name="VisitID" onChange={this.selectKeyNames}/> VisitID</div>
										<div className="col-md-4"> <input type="checkbox" name="InitiatingFormID" onChange={this.selectKeyNames}/> InitiatingFormID</div>
										<div className="col-md-4"> <input type="checkbox" name="LinkUrl" onChange={this.selectKeyNames}/> LinkUrl</div>
										
										<div className="col-md-4"> <input type="checkbox" name="VideoDocumentationFormID" onChange={this.selectKeyNames}/> VideoDocumentationFormID</div>
										<div className="col-md-4"> <input type="checkbox" name="DigitalFabricationFormID" onChange={this.selectKeyNames}/> DigitalFabricationFormID</div>
										<div className="col-md-4"> <input type="checkbox" name="PrescriptionID" onChange={this.selectKeyNames}/> PrescriptionID</div>
										<div className="col-md-4"> <input type="checkbox" name="CodeJustificationDocListID" onChange={this.selectKeyNames}/>  CodeJustificationDocListID</div>
										<div className="col-md-4"> <input type="checkbox" name="ResponseID" onChange={this.selectKeyNames}/> ResponseID</div>
										<div className="col-md-4"> <input type="checkbox" name="CodeSelectionDocListID" onChange={this.selectKeyNames}/> CodeSelectionDocListID</div>
										<div className="col-md-4"> <input type="checkbox" name="CustomerLayoutGUID" onChange={this.selectKeyNames}/> CustomerLayoutGUID</div>
									</div>
									
									<label>Go To Link Syntax for this type: </label>
									<input className="form-control" name="gtLinkTypeSyntax" type="text" placeholder="Type Syntax" onChange={this.getValue}/>
									
								</div>
								}
								<label>Tags: </label>
								<input className="form-control" name="gtLinkTags" type="text" placeholder="Tags" onChange={this.getValue}/>

							</div>
							<div className="modal-footer">
								<button type="button" className="btn btn-primary float-left" onClick={()=>this.addGotoLink()}>Save</button>
								<button type="button" className="btn btn-danger" data-dismiss="modal">Close</button>
							</div>
						</div>
					</div>
				</div>
				
				<div className="modal" id={'editGotoLink'}>
					<div className="modal-dialog modal-lg modal-sm select-modal">
						<div className="modal-content">
							<div className="modal-header">
								<h4 className="modal-title">Edit Goto Link</h4>
								<button type="button" className="close" data-dismiss="modal">&times;</button>
							</div>
							<div className="modal-body">
								<label> Go-To Link: </label>
								<div className="goto-radio">
									<div className="btn-goto-radio"><input type="checkbox" value="Static"  name="eurltype" checked={this.state.eurltype === 'Static'} onChange={this.getValue} /> Static</div>
									<div className="btn-goto-radio"><input type="checkbox" value="Dynamic"  name="eurltype" checked={this.state.eurltype === 'Dynamic'} onChange={this.getValue} /> Dynamic</div>
								</div>
								
								<label> Category: </label>
								<select className="form-control w-edit-category" name="egtLinkCategory" value={this.state.egtLinkCategory} onChange={this.getValue}>
									<option value={''}>Select Category</option>
									{editCategoryOption}
								</select>
								
								<label> Subcategory: </label>
								<select className="form-control w-edit-category" name="egtLinkSubcategory" value={this.state.egtLinkSubcategory} onChange={this.getValue}>
									<option value={''}>Select Category</option>
									{editSubCategoryOption}
								</select>
								
								<label> Name: </label>
								<input className="form-control" name="egtLinkName" value={this.state.egtLinkName} type="text" placeholder="Name" onChange={this.getValue}/>
								
								<label>Description: </label>
								<input className="form-control" name="egtLinkDes" value={this.state.egtLinkDes} type="text" placeholder="Description" onChange={this.getValue}/>
								<label>Tags: </label>
								<input className="form-control" name="egtLinkTags" value={this.state.egtLinkTags} type="text" placeholder="Tags" onChange={this.getValue}/>

								{this.state.eurltype === 'Static' ? 
								<div className="static-sec"><label>Static URL: </label>
								<input className="form-control" name="egtLinkUrl" value={this.state.egtLinkUrl} type="text" placeholder="URL" onChange={this.getValue}/>
								</div>
								:
								<div className="dynamic-sec">
									<label>Base URL: </label>
									<input className="form-control mb-2" name="gtLinkBaseUrl" type="text" placeholder="Base URL" value={this.state.gtLinkBaseUrl} onChange={this.getValue}/>
									
									<label>Add a keyname to the Go To Link Syntax: </label>
									<div className={'type-checkbox row mb-2'}>
										<div className="col-md-4"><input type="checkbox" name="FacilityID" checked={keyNames.get('FacilityID') ? true : false} onChange={this.selectKeyNames}/> FacilityID </div>
										<div className="col-md-4"><input type="checkbox" name="PatientBranchID" checked={keyNames.get('PatientBranchID') ? true : false} onChange={this.selectKeyNames}/> PatientBranchID</div>
										<div className="col-md-4"> <input type="checkbox" name="BranchID" checked={keyNames.get('BranchID') ? true : false} onChange={this.selectKeyNames}/> BranchID</div>
										
										<div className="col-md-4"> <input type="checkbox" name="patientID" checked={keyNames.get('PatientID') ? true : false} onChange={this.selectKeyNames}/> PatientID</div>
										<div className="col-md-4"> <input type="checkbox" name="PatientFirstName" checked={keyNames.get('PatientFirstName') ? true : false} onChange={this.selectKeyNames}/> PatientFirstName</div>
										<div className="col-md-4"> <input type="checkbox" name="PatientLastName" checked={keyNames.get('PatientLastName') ? true : false} onChange={this.selectKeyNames}/> PatientLastName</div>
										
										<div className="col-md-4"> <input type="checkbox" name="TreatingPractitionerID" checked={keyNames.get('TreatingPractitionerID') ? true : false} onChange={this.selectKeyNames}/> TreatingPractitionerID</div>
										<div className="col-md-4"> <input type="checkbox" name="InsuranceCompany" checked={keyNames.get('InsuranceCompany') ? true : false} onChange={this.selectKeyNames}/> InsuranceCompany</div>
										<div className="col-md-4"> <input type="checkbox" name="RxID" checked={keyNames.get('RxID') ? true : false} onChange={this.selectKeyNames}/> RxID</div>
										
										<div className="col-md-4"> <input type="checkbox" name="ClaimID" checked={keyNames.get('ClaimID') ? true : false} onChange={this.selectKeyNames}/> ClaimID</div>
										<div className="col-md-4"> <input type="checkbox" name="VisitID" checked={keyNames.get('VisitID') ? true : false} onChange={this.selectKeyNames}/> VisitID</div>
										<div className="col-md-4"> <input type="checkbox" name="InitiatingFormID" checked={keyNames.get('InitiatingFormID') ? true : false} onChange={this.selectKeyNames}/> InitiatingFormID</div>
										<div className="col-md-4"> <input type="checkbox" name="LinkUrl" checked={keyNames.get('LinkUrl') ? true : false} onChange={this.selectKeyNames}/> LinkUrl</div>
										
										<div className="col-md-4"> <input type="checkbox" name="VideoDocumentationFormID" checked={keyNames.get('VideoDocumentationFormID') ? true : false} onChange={this.selectKeyNames}/> VideoDocumentationFormID</div>
										<div className="col-md-4"> <input type="checkbox" name="DigitalFabricationFormID" checked={keyNames.get('DigitalFabricationFormID') ? true : false} onChange={this.selectKeyNames}/> DigitalFabricationFormID</div>
										<div className="col-md-4"> <input type="checkbox" name="PrescriptionID" checked={keyNames.get('PrescriptionID') ? true : false} onChange={this.selectKeyNames}/> PrescriptionID</div>
										<div className="col-md-4"> <input type="checkbox" name="CodeJustificationDocListID" checked={keyNames.get('CodeJustificationDocListID') ? true : false} onChange={this.selectKeyNames}/> CodeJustificationDocListID</div>
										
										<div className="col-md-4"> <input type="checkbox" name="ResponseID" checked={keyNames.get('ResponseID') ? true : false} onChange={this.selectKeyNames}/> ResponseID</div>
										<div className="col-md-4"> <input type="checkbox" name="CodeSelectionDocListID" checked={keyNames.get('CodeSelectionDocListID') ? true : false} onChange={this.selectKeyNames}/> CodeSelectionDocListID</div>
										<div className="col-md-4"> <input type="checkbox" name="CustomerLayoutGUID" onChange={this.selectKeyNames} checked={keyNames.get('CodeSelectionDocListID') ? true : false} /> CustomerLayoutGUID</div>
									</div>
									
									<label>Go To Link Syntax for this type: </label>
									<input className="form-control" name="gtLinkTypeSyntax" type="text" placeholder="Type Syntax" value={this.state.gtLinkTypeSyntax} onChange={this.getValue}/>
									
								</div>
								}
								
								<label> Status: </label>
								<select className="form-control w-edit-category" name="egtLinkActive" value={this.state.egtLinkActive} onChange={this.getValue}>
									<option value={1}>Active</option>
									<option value={0}>Inactive</option>
								</select>
							</div>
							<div className="modal-footer">
								<button type="button" className="btn btn-primary float-left" onClick={()=>this.updateGotoLink()}>Update</button>
								<button type="button" className="btn btn-danger" data-dismiss="modal">Close</button>
							</div>
						</div>
					</div>
				</div>
				
				
			</div>
		);
		
	}
}
