import React, { Component } from 'react';
import $ from 'jquery';
import axios,{post,get} from 'axios';
import moment from 'moment';
import { ulid } from 'ulid'
import arrayMove from "./arrayMove";
import { CSVLink } from "react-csv";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Html5QrcodeScanner } from "html5-qrcode";
import Html5QrcodePlugin  from "./Html5QrcodePlugin";
import ResultContainerPlugin from './ResultContainerPlugin';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import {stateFromHTML} from 'draft-js-import-html';
import {convertToRaw, EditorState, convertFromHTML, convertFromRaw,ContentState} from 'draft-js';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Checkbox from '@mui/material/Checkbox';
import OutlinedInput from '@mui/material/OutlinedInput';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import { Button, Table, TableBody, TableContainer, TableHead, TableRow } from '@mui/material';
import Paper from '@mui/material/Paper';
import TablePagination from '@mui/material/TablePagination';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';

import {
  sortableContainer,
  sortableElement,
  sortableHandle,
} from 'react-sortable-hoc';


export default class goToValues extends Component {
	
	constructor(props){
        super(props)
		this.state ={
			gotolinks:[],
			gotolinksFilter:[],
			allCategories:[],
			keyNames:new Map(),
			selectedKeyNames:'',
			categories:[],
			subcategories:[],
			deviceTypes:[],
			visitTypes:[],
			tags:[],
			filterDeviceType:[],
			filterVisitType:[],
			filterTags:[],
			cusCategoryName:'',
			addEditCategory:false,
			showGuidTxt:false,
			addEditSubCategory:false,
			urltype:'Static',
			gtLinkTypeSyntax:'',
			page:0,
			rowsPerPage:10,
		}
    }
	
	componentDidMount() {
		this.loadScript();
		this.getAllCategories();
		this.getAllGotoLinks();
		this.getCategories();
	}
	
	loadScript() {
		$(function() {
		  'use strict';
		  $('.js-menu-toggle').click(function(e) {
			var $this = $(this);
			if ( $('#wrapper').hasClass('toggled') ) {
				$('#wrapper').removeClass('toggled');
				$this.removeClass('active');
			} else {
				$('#wrapper').addClass('toggled');	
				$this.addClass('active');
			}
			e.preventDefault();
		  });
		});
	}
	
	getValue=(event)=>{
		let name = event.target.name;
		let res = event.target.value;
		this.setState({[event.target.name]:event.target.value});
		
		if(name == 'wEditSubCategory'){
			let selectedCategoryName = event.target.selectedOptions[0].text;
			this.setState({addEditSubCategory:false,wsubcatName:selectedCategoryName});
		}
		if(name == 'wEditCategory'){
			this.getSubCategories(res);
			let selectedCategoryName = event.target.selectedOptions[0].text;
			this.setState({addEditCategory:false,wcatName:selectedCategoryName}); 
		}
		
		if(name == 'filterCategory' || name == 'addLinkCategory' || name == 'egtLinkCategory'){
			this.getSubCategories(res);
		}
		
		if(name == 'filterCategory' || name == 'filterSubCategory' || name == 'filterDeviceType' || name == 'filterVisitType' || name == 'filterTags' || name == 'filterStatus'){
			let that = this;
			setTimeout(function(){
				that.dataFilter();
			}, 1000);
		}
		
		if(name == 'filterCategory'){
			this.setState({addLinkCategory:res});
		}
		
		if(name == 'filterSubCategory'){
			this.setState({addLinkSubcategory:res});
		}
		
		let that = this;
		if(name == 'gtLinkBaseUrl'){
			$("input[name='gtLinkTypeSyntax']").val(res);
			/* setTimeout(function(){
				that.setLinkSyntaxUrl();
			}, 1000); */
		}
		
		if(name == 'filterDeviceType'){
			//console.log('res->',res);
		}
	}
	
	addValueForm=(event)=>{
		let name = event.target.name;
		let res = event.target.value;
		this.setState({[event.target.name]:event.target.value});
		if(name == 'filterCategory'){
			this.getSubCategories(res);
		}
	}
	
	getAllGotoLinks=()=>{
		let ApiUrl = $('#ApiUrl').val();
		let that = this;
		let url = ApiUrl+'gotolink-values';
		let data = [];
		axios.get(url)
        .then(response => {
            data = response.data;
			this.setState({gotolinks:data});
			setTimeout(function(){
				that.dataFilter();
			}, 1000);
        })
	}
	
	getAllCategories=()=>{
		let ApiUrl = $('#ApiUrl').val();
		let that = this;
		let url = ApiUrl+'all-subcategories';
		let data = [];
        axios.get(url)
        .then(response => {
            data = response.data;
			this.setState({allCategories:data});
        })
	}
	
	getCategories=()=>{
		let ApiUrl = $('#ApiUrl').val();
		let that = this;
		let url = ApiUrl+'gotolink-categories';
		let data = [];
        axios.get(url)
        .then(response => {
            data = response.data;
			this.setState({categories:data});
        })
	}
	
	getSubCategories=(id)=>{
		let ApiUrl = $('#ApiUrl').val();
		let that = this;
		let url = ApiUrl+'gotolink-subcategories/'+id;
		let data = [];
		axios.get(url)
        .then(response => {
            data = response.data;
			this.setState({subcategories:data,egtLinkSubcategory:0});
        })
	}
	
	dataFilter=() => {
		let gotolinksFilter = [];
		let that = this;
		let deviceTypesArr = [];
		let visitTypesArr = [];
		let tagsArr = [];
		this.state.gotolinks.map(function(row,i) {
			
			
			//tags.push(row.tags);

			if(that.state.filterCategory && that.state.filterCategory != row.category){
				return false;
			}
			
			if(that.state.filterSubCategory && that.state.filterSubCategory != row.subcategory){
				return false;
			}
			
			/* if(that.state.filterTags && that.state.filterTags != row.tags){
				return false;
			} */
			
			
			
			let dTypes = row.associated_device_types.split(',');
			dTypes.map(function(ty,i) {
				deviceTypesArr.push(ty.trim());
			});
			
			if(that.state.filterDeviceType.length > 0){
				let flt = true;
				that.state.filterDeviceType.map(function(ty,i) {
					if(dTypes.indexOf(ty) != -1){
						flt = false;
					}
				});
				if(flt){
					return false;
				}
			}
			
			let vTypes = row.associated_visit_types.split(',');
			vTypes.map(function(ty,i) {
				visitTypesArr.push(ty.trim());
			});
			
			if(that.state.filterVisitType.length > 0){
				let fltl = true;
				that.state.filterVisitType.map(function(ty,i) {
					if(vTypes.indexOf(ty) != -1){
						fltl = false;
					}
				});
				if(fltl){
					return false;
				}
			}
			
			let tags = row.tags.split(',');
			tags.map(function(tg,i) {
				tagsArr.push(tg.trim());
			});
			
			
			if(that.state.filterTags.length > 0){
				let fltl = true;
				that.state.filterTags.map(function(ty,i) {
					if(tags.indexOf(ty) != -1){
						fltl = false;
					}
				});
				if(fltl){
					return false;
				}
			}
			console.log('row->',row);
			if(that.state.filterStatus && that.state.filterStatus != row.status){
				return false;
			} 
			
			gotolinksFilter.push(row);
		});
		
		let deviceTypes = this.uniqueArray(deviceTypesArr);
		let visitTypes = this.uniqueArray(visitTypesArr);
		let tags = this.uniqueArray(tagsArr);
		
		console.log('tags->',tags);
		
		this.setState({gotolinksFilter,page:0,tags,deviceTypes,visitTypes});
	}
	
	uniqueArray(arr) {
		var a = [];
		for (var i=0, l=arr.length; i<l; i++)
			if (a.indexOf(arr[i]) === -1 && arr[i] !== '')
				a.push(arr[i]);
		return a;
	}
	
	addCategory=() => {
		this.setState({addEditCategory:true,cusCategoryName:'',cusSubCategoryName:'',editcatId:''});
	}
	
	addSubCategory=() => {
		this.setState({addEditSubCategory:true,cusSubCategoryName:'',editcatId:''});
	}
	
	editCategory=() => {
		let catName = this.state.wcatName;
		this.setState({addEditCategory:true,cusCategoryName:catName,editcatId:this.state.wEditCategory});
	}
	
	editSubCategory=() => {
		let catName = this.state.wsubcatName;
		this.setState({addEditSubCategory:true,cusSubCategoryName:catName,editcatId:this.state.wEditSubCategory});
	}
	
	addUpdateCategory=() => {
		
		let catName = this.state.cusCategoryName;
		let wEditCategory = this.state.wEditCategory;
		let catSubName = this.state.cusSubCategoryName;
		let editcatId = this.state.editcatId;
		if(catName || catSubName){
			let ApiUrl = $('#ApiUrl').val();
			let url = ApiUrl+'add-gotolink-category';
			let formData = new FormData();
			formData.append('id', editcatId);
			if(catSubName){
				formData.append('parent_id', wEditCategory);
				formData.append('name', catSubName);
			}else{
				formData.append('name', catName);
			}
			axios({
				method: 'POST',
				url: url,
				data: formData,
				headers: {
					'Content-Type': 'multipart/form-data'
				}
			})
			.then(response => {
				if(response.data){
					if(catSubName){
						this.getSubCategories(wEditCategory);
						this.setState({wsubcatName:'',addEditSubCategory:false});
					}else{
						$('.w-edit-category').val('');
						this.getCategories();
						this.setState({cusCategoryName:'',cusSubCategoryName:'',addEditCategory:false,wcatName:'',wsubcatName:'',wEditCategory:'',addEditSubCategory:false});
					}
					alert(response.data);
					
				}
			}).catch(error => {
				alert('error::'+ error);
			})
		}
	}
	
	handleChangePage = (event: unknown, newPage: number) => {
		this.setState({page:newPage});
	};

	handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
		this.setState({rowsPerPage:event.target.value,page:0});
	};
	

	addGotoLinkValue=() => {
		
		let gtcategory = this.state.filterCategory;
		let gtSubcategory = this.state.filterSubCategory;
		let groupID = 'GBY57T83-H4T1-6723-ANH5-6G5234YR87V6';
		let faciliityID = 'A2130BEB-D932-4587-B8F3-57A667EA814C';
		let gtLinkName = this.state.gtLinkName;
		let gtLinkGuid = this.state.gtLinkGuid;
		let gtLinkdes = this.state.gtLinkdes;
		let gtLinktags = this.state.gtLinktags;
		let gtLinkType = this.state.gtLinkType;
		let gtLinkdType = this.state.gtLinkdType;
		let gtLinkvType = this.state.gtLinkvType;
		let gtstatus = this.state.gtstatus ? this.state.gtstatus : 1;
		
		if(gtLinkName && gtLinkGuid){
			
			let ApiUrl = $('#ApiUrl').val();
			let url = ApiUrl+'add-glink-value';
			let uid = ulid();
			let formData = new FormData();
			formData.append('groupID', groupID);
			formData.append('faciliityID', faciliityID);
			formData.append('category', gtcategory);
			formData.append('subcategory', gtSubcategory);
			formData.append('name', gtLinkName);
			formData.append('guid', gtLinkGuid);
			formData.append('note', gtLinkdes);
			formData.append('tags', gtLinktags);
			formData.append('type', gtLinkType);
			formData.append('deviceType', gtLinkdType);
			formData.append('visitType', gtLinkvType);
			formData.append('status', gtstatus);
			axios({
				method: 'POST',
				url: url,
				data: formData,
				headers: {
					'Content-Type': 'multipart/form-data'
				}
			})
			.then(response => {
				if(response.data){
					this.getAllGotoLinks();
					this.setState({filterCategory:'',filterSubCategory:'',gtLinkName:'',gtLinkGuid:'',gtLinktags:'',gtLinkType:'',gtLinkdType:'',gtLinkvType:'',gtstatus:''});
					alert(response.data);
					$('#addGotoLinkValue').modal('hide');
				}
			}).catch(error => {
				alert('error::'+ error);
			})
		}else{
			alert("Please insert Goto link data correctly!");
		}
	}
	
	editGotoLink=(val) => {
		
		this.setState({keyNames:new Map()});
		let egtgotoId = val.id;
		let egtSubcategory = val.subcategory;
		let egtLinkName = val.layout_name;
		let egtLinkGuid = val.layout_guid;
		let egtLinktags = val.tags;
		let egtstatus = val.status;
		this.getSubCategories(val.category);
		
		this.setState({egtgotoId,egtSubcategory,egtLinkName,egtLinkGuid,egtstatus,egtLinktags});
		
		$('#editGotoLink').modal('show');
	}

	updateGotoLink=() => {
		
		let egtgotoLinkId = this.state.egtgotoId;
		let egtSubcategory = this.state.egtSubcategory;
		let egtLinkName = this.state.egtLinkName;
		let egtLinkGuid = this.state.egtLinkGuid;
		let egtLinktags = this.state.egtLinktags;
		let egtstatus = this.state.egtstatus;
		
		if(egtgotoLinkId && egtLinkName && egtstatus){
			
			let ApiUrl = $('#ApiUrl').val();
			let url = ApiUrl+'edit-gotolink-value';
			let uid = ulid();
			let formData = new FormData();
			formData.append('id', egtgotoLinkId);
			formData.append('subcategory', egtSubcategory);
			formData.append('name', egtLinkName);
			formData.append('guid', egtLinkGuid);
			formData.append('tags', egtLinktags);
			formData.append('status', egtstatus);
			axios({
				method: 'POST',
				url: url,
				data: formData,
				headers: {
					'Content-Type': 'multipart/form-data'
				}
			})
			.then(response => {
				if(response.data){
					this.getAllGotoLinks();
					this.setState({egtgotoLinkId:'',egtLinkSubcategory:'',egtLinkName:'',egtLinkGuid:'',egtstatus:'',egtLinktags:''});
					alert(response.data);
					$('#editGotoLink').modal('hide');
				}
			}).catch(error => {
				alert('error::'+ error);
			})
		}else{
			alert("Please insert Goto link data correctly!");
		}
	}
	
	copyGuidCode=(copyText)=>{
		navigator.clipboard.writeText(copyText);
	}
	
	deleteGotolink=(id)=>{
		if(id){
			let ApiUrl = $('#ApiUrl').val();
			let url = ApiUrl+'delete-gotolink/'+id;
			axios({
				method: 'delete',
				url: url,
			})
			.then(response => {
				this.getAllGotoLinks();
			}).catch(error => {
				alert('error::'+ error);
			})
		}
	}

	/* getGotoLinkType=()=>{
		let ApiUrl = $('#ApiUrl').val();
		let that = this;
		let url = ApiUrl+'gotolink-types';
		let data = [];
		axios.get(url)
        .then(response => {
            data = response.data;
			this.setState({gotolinkTypes:data});
        })
	} */
	
	selectKeyNames=(event)=>{
		let name = event.target.name;
		let keyNameArr = this.state.keyNames;
		if($("input[name='"+name+"']").prop("checked") == true){
			let urlstr = $("input[name='gtLinkTypeSyntax']").val();
			
			$("input[name='gtLinkTypeSyntax']").val(urlstr+'/'+'{'+name+'}');
			
			let gtLinkTypeSyntax = urlstr+'/'+'{'+name+'}';
			
			this.setState({gtLinkTypeSyntax});

			keyNameArr.set(name,name);
			
		}else if($("input[name='"+name+"']").prop("checked") == false){
			keyNameArr.delete(name);
		}
		
		this.setState({keyNames:keyNameArr});
		
		let that = this;
		setTimeout(function(){
			that.setLinkSyntaxUrl();
		}, 1000); 
	}
	
	setLinkSyntaxUrl=()=>{
		let keyNameArr = this.state.keyNames;
		if(keyNameArr){
			let keyNameArrs = Object.fromEntries(keyNameArr.entries());
			let calArr = [];
			for (let prop in keyNameArrs) {
				calArr.push(keyNameArrs[prop]);
			}
			calArr = calArr.filter(function(x) {
				 return x !== '';
			});
			
			keyNameArr = calArr.join(',');
			
		}
		
		this.setState({selectedKeyNames:keyNameArr});
	}
	
	/* setLinkSyntaxUrl=()=>{
		let keyNameArr = this.state.keyNames;
		let gtLinkBaseUrl = this.state.gtLinkBaseUrl ? this.state.gtLinkBaseUrl : 'https://example.com/';
		let syntaxParms = '';
		if(keyNameArr){
			let keyNameArrs = Object.fromEntries(keyNameArr.entries());
			let calArr = [];
			for (let prop in keyNameArrs) {
				calArr.push(keyNameArrs[prop]);
			}
			calArr = calArr.filter(function(x) {
				 return x !== '';
			});
			
			keyNameArr = calArr.join('/');
			
			let strUrl = [];
			if(calArr.length > 0){
				calArr.map((val, index) => {
					let ur = '{'+val+'}';
					strUrl.push(ur);
				})
			}
			syntaxParms = strUrl.join('/');
		}
		
		let syntax = gtLinkBaseUrl+'/'+syntaxParms;
		this.setState({selectedKeyNames:keyNameArr,gtLinkTypeSyntax:syntax});
		$("input[name='gtLinkTypeSyntax']").val(syntax);
	} */
	
	deleteCategory=(id) => {
		//let id = this.state.wEditCategory;
		if(id){
			let ApiUrl = $('#ApiUrl').val();
			let url = ApiUrl+'delete-goto-category/'+id;
			axios({
				method: 'delete',
				url: url,
			})
			.then(response => {
				if(response.data == 1){
					this.getCategories();
					alert('The category deleted successfully!');
				}else{
					alert("We can't delete category unless all Go-To link are Moved or Deleted from the category.");
				}
			}).catch(error => {
				alert('error::'+ error);
			})
		}
	}
	
	deleteSubcategory=(id) => {
		if(id){
			let ApiUrl = $('#ApiUrl').val();
			let url = ApiUrl+'delete-goto-subcategory/'+id;
			axios({
				method: 'delete',
				url: url,
			})
			.then(response => {
				if(response.data == 1){
					this.getSubCategories(this.state.wEditCategory);
					alert('The subcategory deleted successfully!');
				}else{
					alert("We can't delete subcategory unless all Go-To link are Moved or Deleted from the category.");
				}
			}).catch(error => {
				alert('error::'+ error);
			})
		}
	}
	
	showGuid=(id,type)=>{
		if(type){
			this.setState({[id]:true});
		}else{
			this.setState({[id]:false});
		}
	}
	
	render() {

		const {gotolinks,gotolinksFilter,allCategories,categories,subcategories,addEditCategory,addEditSubCategory,cusCategoryName,cusSubCategoryName,keyNames,tags,filterTags,egtgotoId,egtSubcategory,egtLinkName,egtLinkGuid,egtLinktags,egtstatus,deviceTypes,visitTypes} = this.state;
		
		let href = window.location.href.split('?')[0];
		
		//console.log('filterTags->',filterTags);
		
		let editCategoryOption = categories.map(function(val,i) {
			return (
				<option value={val.id} key={i}>{val.name}</option>
			);
		})
		
		let editSubCategoryOption = subcategories.map(function(val,i) {
			return (
				<option value={val.id} key={i}>{val.name}</option>
			);
		})
		
		let tagsOption = tags.map(function(val,i) {
			return (
				<option value={val} key={i}>{val}</option>
			);
		})
		
		
		const StyledTableCell = styled(TableCell)(({ theme }) => ({
		  [`&.${tableCellClasses.head}`]: {
			backgroundColor: '#e9e9e9',
			color: '#000;',
			fontWeight: 600,
			fontSize: 15,
		  },
		  [`&.${tableCellClasses.body}`]: {
			fontSize: 14,
		  },
		}));
		
		return (
			<div>
				<aside className="sidebar">
				  <div className="toggle toggle-btn">
					<a href="#" className="burger js-menu-toggle active" data-toggle="collapse" data-target="#main-navbar">
						<span></span>
					</a>
				  </div>
				  <div className="side-inner align-items-end" >
					<div className="sidebar-content">
						<div className="nav-content">
							<h5 className="page-title">Go-to Link Filter</h5>
							<div className="panel-btn">
								<div className="input-group mb-3">
									<select className="form-control builder-filter" name="filterCategory" onChange={this.getValue}>
										<option value={''}>Select Category</option>
										{editCategoryOption}
									</select>
								</div>
								
								{subcategories.length > 0 ?
								<div className="input-group mb-3">
									<select className="form-control builder-filter" name="filterSubCategory" onChange={this.getValue}>
										<option value={''}>Select Subcategory</option>
										{editSubCategoryOption}
									</select>
								</div>
								:null}
								
								{/* tags.length > 0 ?
								<div className="input-group mb-3">
									<select className="form-control builder-filter" name="filterTags" onChange={this.getValue}>
										<option value={''}>Select Tag</option>
										{tagsOption}
									</select>
								</div>
								:null */}
														
							</div>

						</div>
					</div>
				  </div>
				</aside>
				
				<div id="page-content-wrapper" className="workflow-manager">
					<div className="workflow-list">
						<div className="row">
							<div className="col-md-12 mb-4">
								<h4>Go-To Link Form Values</h4>
							</div>
						</div>
						<div className="goto-filters">
							<div className="wp-filter">
								<FormControl sx={{ m: 1, minWidth: 200, maxWidth: 200 }} size="small">
									<InputLabel>Device Type</InputLabel>
									<Select
										multiple
										value={this.state.filterDeviceType ? this.state.filterDeviceType : []}
										name={'filterDeviceType'}
										onChange={this.getValue}
										input={<OutlinedInput label="Device Type" />}
										
									>
									<MenuItem value={''} >None</MenuItem>
									{deviceTypes.map(function(val,i) {
										return (
											<MenuItem value={val} key={i}>{val}</MenuItem>
										);
									})}
									</Select>
								</FormControl>
							</div>
							<div className="wp-filter">
								<FormControl sx={{ m: 1, minWidth: 200, maxWidth: 200 }} size="small">
									<InputLabel>Visit Type</InputLabel>
									<Select
										multiple
										value={this.state.filterVisitType ? this.state.filterVisitType : []}
										name={'filterVisitType'}
										onChange={this.getValue}
										input={<OutlinedInput label="Visit Type" />}
									>
									<MenuItem value={''} >None</MenuItem>
									{visitTypes.map(function(val,i) {
										return (
											<MenuItem value={val} key={i}>{val}</MenuItem>
										);
									})}
									</Select>
								</FormControl>
							</div>
							<div className="wp-filter">
								<FormControl sx={{ m: 1, minWidth: 200, maxWidth: 200 }} size="small">
									<InputLabel>Tags</InputLabel>
									<Select
										multiple
										value={this.state.filterTags ? this.state.filterTags : []}
										name={'filterTags'}
										onChange={this.getValue}
										input={<OutlinedInput label="Tags" />}
									>
									<MenuItem value={''} >None</MenuItem>
									{tags.map(function(val,i) {
										return (
											<MenuItem value={val} key={i}>{val}</MenuItem>
										);
									})}
									</Select>
								</FormControl>
							</div>
							
							<div className="wp-filter">
								<FormControl sx={{ m: 1, minWidth: 200, maxWidth: 200 }} size="small">
									<InputLabel>Status</InputLabel>
									<Select
										value={this.state.filterStatus ? this.state.filterStatus : ""}
										name={'filterStatus'}
										onChange={this.getValue}
										input={<OutlinedInput label="Status" />}
									>
									<MenuItem value={''} >Active / Inactive</MenuItem>
									<MenuItem value={'1'} >Active</MenuItem>
									<MenuItem value={'0'} >Inactive</MenuItem>
									</Select>
								</FormControl>
							</div>
							
							<div className="add-link-value"><button className="btn btn-primary btn-sm" type="button" data-toggle="modal" data-target="#addGotoLinkValue" >+ Go-To Link Value</button></div>
							
						</div>
						<Paper sx={{ width: '100%', overflow: 'hidden' }}>
							<TableContainer>
							  <Table size="small" aria-label="a dense table">
								<TableHead>
								  <TableRow>
									<StyledTableCell>Category</StyledTableCell>
									<StyledTableCell>Subategory</StyledTableCell>
									<StyledTableCell>Form Name</StyledTableCell>
									<StyledTableCell>Form Note</StyledTableCell>
									<StyledTableCell>CustomerLayoutGUID </StyledTableCell>
									
									<StyledTableCell>Device Types</StyledTableCell>
									<StyledTableCell>Visit Types</StyledTableCell>
									<StyledTableCell>Tags</StyledTableCell>
									<StyledTableCell>Status</StyledTableCell>
									<StyledTableCell>&nbsp;</StyledTableCell>
									
								  </TableRow>
								</TableHead>
								<TableBody>
								  {gotolinksFilter.slice(this.state.page * this.state.rowsPerPage, this.state.page * this.state.rowsPerPage + this.state.rowsPerPage).map((row) => {
									let catName = '';
									allCategories.map(function(cat,i) {
										if(cat.id == row.category){
											catName = cat.name;
										}
										
									})
									
									let subcatName = '';
									allCategories.map(function(cat,i) {
										if(cat.id == row.subcategory){
											subcatName = cat.name;
										}
										
									})
							
									return (<TableRow key={row.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
									  <TableCell className="gt-td-width">{catName}</TableCell>
									  <TableCell className="gt-td-width">{subcatName}</TableCell>
									  <TableCell className="gt-td-width">{row.layout_name}</TableCell>
									  <TableCell className="gt-td-width"><p>{row.layout_note}</p></TableCell>
									  <TableCell className="gt-td-width">
										{this.state[row.layout_guid] ? 
										<div className="eye-giud"><i className="fa fa-eye-slash" aria-hidden="true" onClick={()=>this.showGuid(row.layout_guid,false)}></i>&nbsp; <span>{row.layout_guid}</span></div>
										:
										<i className="fa fa-eye" aria-hidden="true" onClick={()=>this.showGuid(row.layout_guid,true)}></i>
										}
									  </TableCell>
									  <TableCell>{row.associated_device_types}</TableCell>
									  <TableCell>{row.associated_visit_types}</TableCell>
									  <TableCell>{row.tags}</TableCell>
									 
									  
									  <TableCell className={"text-center"}><input type="checkbox" value="Static"  name="urltype" checked={row.status === 1}  /></TableCell>
									  <TableCell>
									  <div className="d-flex">
										<button onClick={()=>this.editGotoLink(row)} type="button" className="btn"><img src={href+'/Icon-Edit.png'} alt="edit" width="18" height="18"/></button>
									  </div>
									  </TableCell>
									  
									</TableRow>)
								  })}
								</TableBody>
							  </Table>
							</TableContainer>
							<TablePagination
								rowsPerPageOptions={[5, 10, 20, 50, 100]}
								component="div"
								count={gotolinksFilter.length}
								rowsPerPage={this.state.rowsPerPage}
								page={this.state.page}
								onPageChange={this.handleChangePage}
								onRowsPerPageChange={this.handleChangeRowsPerPage}
							  />
						</Paper>
					</div>
				</div>
				
				<div className="modal" id={'addGotoLinkValue'}>
					<div className="modal-dialog modal-lg modal-sm select-modal">
						<div className="modal-content">
							<div className="modal-header">
								<h4 className="modal-title">Add Dynamic Goto Link Form Value</h4>
								<button type="button" className="close" data-dismiss="modal">&times;</button>
							</div>
							<div className="modal-body">
								<label> Category: </label>
								<select className="form-control" name="filterCategory" value={this.state.filterCategory} onChange={this.addValueForm}>
									<option value={''}>Select Category</option>
									{editCategoryOption}
								</select>
								
								{subcategories.length > 0 ?
								<div>
									<label> Subcategory: </label>
									<select className="form-control" name="filterSubCategory" value={this.state.filterSubCategory} onChange={this.addValueForm}>
										<option value={''}>Select Subcategory</option>
										{editSubCategoryOption}
									</select>
								</div>
								:null}
								
								<label> Form Name: </label>
								<input className="form-control" name="gtLinkName" value={this.state.gtLinkName} type="text" placeholder="form name" onChange={this.getValue}/>
								
								<label> CustomerLayoutGUID : </label>
								<input className="form-control" name="gtLinkGuid" value={this.state.gtLinkGuid} type="text" placeholder="Form Guid" onChange={this.getValue}/>
								
								<label>Description: </label>
								<input className="form-control" name="gtLinkdes" type="text" placeholder="Description" onChange={this.getValue}/>
								
								<label> Tags: (e.g tag1,tag2)</label>
								<input className="form-control" name="gtLinktags" type="text" placeholder="tag1, tag2" onChange={this.getValue}/>
								
								<label> Associated Type: </label>
								<input className="form-control" name="gtLinkType" type="text" placeholder="associated type" onChange={this.getValue}/>
								
								<label> Device Types: (e.g type1,type2)</label>
								<input className="form-control" name="gtLinkdType" type="text" placeholder="type1,type2" onChange={this.getValue}/>
								
								<label> Visit Types: (e.g type1,type2)</label>
								<input className="form-control" name="gtLinkvType" type="text" placeholder="type1,type2" onChange={this.getValue}/>
								
								<label> Status: </label>
								<select className="form-control w-edit-category" name="gtstatus" onChange={this.getValue}>
									<option value={1}>Active</option>
									<option value={0}>Inactive</option>
								</select>
							</div>
							<div className="modal-footer">
								<button type="button" className="btn btn-primary float-left" onClick={()=>this.addGotoLinkValue()}>Save</button>
								<button type="button" className="btn btn-danger" data-dismiss="modal">Close</button>
							</div>
						</div>
					</div>
				</div>
				
				<div className="modal" id={'editGotoLink'}>
					<div className="modal-dialog modal-lg modal-sm select-modal">
						<div className="modal-content">
							<div className="modal-header">
								<h4 className="modal-title">Edit Dynamic Goto Link Form Value</h4>
								<button type="button" className="close" data-dismiss="modal">&times;</button>
							</div>
							<div className="modal-body">
								
								<label> Subcategory: </label>
								<select className="form-control w-edit-category" name="egtSubcategory" value={this.state.egtSubcategory} onChange={this.getValue}>
									<option value={0}>Select Category</option>
									{editSubCategoryOption}
								</select>
								
								<label> Form Name: </label>
								<input className="form-control" name="egtLinkName" value={this.state.egtLinkName} type="text" placeholder="Name" onChange={this.getValue}/>
								
								<label> CustomerLayoutGUID : </label>
								<input className="form-control" name="egtLinkGuid" value={this.state.egtLinkGuid} type="text" placeholder="Form Guid" onChange={this.getValue}/>
								
								<label> Tags: </label>
								<input className="form-control" name="egtLinktags" value={this.state.egtLinktags} type="text" placeholder="tags" onChange={this.getValue}/>
								
								
								<label> Status: </label>
								<select className="form-control w-edit-category" name="egtstatus" value={this.state.egtstatus} onChange={this.getValue}>
									<option value={1}>Active</option>
									<option value={0}>Inactive</option>
								</select>
							</div>
							<div className="modal-footer">
								<button type="button" className="btn btn-primary float-left" onClick={()=>this.updateGotoLink()}>Update</button>
								<button type="button" className="btn btn-danger" data-dismiss="modal">Close</button>
							</div>
						</div>
					</div>
				</div>
				
				
			</div>
		);
		
	}
}
